import React from 'react';
import DynamicFields from './DynamicFields';
import DeliverablesField from './DeliverablesField';
import TableCell from './TableCell';
import TableRow from './TableRow';
import Table from './Table';

class DeliverablesFields extends React.Component {
  constructor(props) {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleCreateField = this.handleCreateField.bind(this);
    this.handleDeleteField = this.handleDeleteField.bind(this);
  }

  handleFieldChange(key, event) {
    const deliverables = this.props.deliverables;
    deliverables[key][event.target.name] = event.target.value;
    this.props.onChange(deliverables);
  }

  handleDeleteField(key, event) {
    const deliverables = this.props.deliverables;
    deliverables.splice(key, 1);
    this.props.onChange(deliverables);
    event.preventDefault();
  }

  handleCreateField(key, event) {
    const deliverables = this.props.deliverables;
    const newDeliverables = [];
    const blankDeliverable = { order: 0, description: null, delivery_format: null, frame_rate: null, resolution: null };
    for (let i=0; i < deliverables.length; i++) {
      newDeliverables.push(deliverables[i]);
      if (i === key) newDeliverables.push(blankDeliverable);
    }
    if (key === deliverables.length || deliverables.length === 0) {
      newDeliverables.push(blankDeliverable);
    }

    this.props.onChange(newDeliverables);
    if (event) event.preventDefault();
  }

  render() {
    const component = this;
    const newKey = component.props.deliverables.length;
    const deliverable = <DeliverablesField key={newKey} name={"deliverables[" + newKey + "]"} value="" onChange={component.handleFieldChange} />;
    const deliverables = this.props.deliverables.map((deliverable, index) =>
      <DeliverablesField
        key={index}
        description={deliverable.description}
        frameRate={deliverable.frame_rate}
        deliveryFormat={deliverable.delivery_format}
        resolution={deliverable.resolution}
        onCreateField={(event) => component.handleCreateField(index, event)}
        onDeleteField={(event) => component.handleDeleteField(index, event)}
        onChange={(event) => component.handleFieldChange(index, event)} />
    );
    return(
      <Table fullwidth>
        {this.props.deliverables.length > 0 &&
          <thead>
            <TableRow>
              <TableCell heading wide>Description</TableCell>
              <TableCell heading>Delivery format</TableCell>
              <TableCell heading>Frame rate</TableCell>
              <TableCell heading narrow>Resolution</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </thead>
        }
        <DynamicFields
          fields={deliverables}
          field={deliverable}
          fieldType="tableRow"
          fieldSingleName="deliverable"
          onCreateField={this.handleCreateField}
          onDeleteField={this.handleDeleteField}
        />
      </Table>
    );
  }
}

export default DeliverablesFields;
