import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import React from 'react'
import { Editor } from 'react-draft-wysiwyg'

export default function IntroductionTextInput({name, html, onChange, disabled, placeholder}) {

  //------
  // Editor

  const [editorState, setEditorState] = React.useState()

  React.useEffect(() => {
    if (html == null) { return undefined }
    const content = htmlToDraft(html)
    console.log('html', html)
    if (content == null) { return undefined }

    const contentState = ContentState.createFromBlockArray(content.contentBlocks);
    setEditorState(EditorState.createWithContent(contentState))
  }, [])
  
  const toolbarOptions = {
    options: ['inline', 'list',],
    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough']
    },
    list: {
      options: ['unordered','ordered', 'indent', 'outdent'],
    },
  }

  function handleEditorStateChange(state) {
    const html = draftToHtml(convertToRaw(state.getCurrentContent()))
    onChange(html)
    setEditorState(state)
  }

  return (
    <Editor
      editorState={editorState}
      readOnly={disabled}
      name={name}
      placeholder={placeholder}
      toolbar={toolbarOptions}
      editorClassName="Editor__editor textarea"
      wrapperClassName={disabled ? "Editor--disabled" : undefined}
      onEditorStateChange={handleEditorStateChange}
      stripPastedStyles
    />
  )
}