import React from 'react';
import Button from '../components/Button';

function PaymentStatus(props) {
  const className = "payment-status " + props.className;
  let variant = null;
  if (props.status === "paid") variant= "success";
  if (props.status === "overdue") variant="danger";
  if (props.status === "overpaid") variant="warning";

  return(
    <Button
      onClick={props.onClick}
      tooltip={(props.readonly ? "View" : "Manage") + " payments"}
      className={className}
      variant={variant}
      label={props.status} />
  )
}

export default PaymentStatus;
