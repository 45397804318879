import React from 'react';

function LineActions(props) {
  return (
    <ul>
      {props.children}
    </ul>
  );
}

export default LineActions;
