import React from 'react';
import Icon from './Icon';

function SiteName({name}) {
  let icon = false;
  if (name === "quota") {
    icon = "file-invoice";
  }
  if (name === "tracka") {
    icon = "wallet";
  }
  return (
    <h1 className="site-name has-text-centered">
      <Icon color="dark" name={icon} />
      {name}
    </h1>
  );
}

export default SiteName;
