import React from 'react';

function Table({fullwidth, hoverable, field, children, className}) {
  let fullClassName = "table";
  if (fullwidth) fullClassName += "  is-fullwidth";
  if (hoverable) fullClassName += " is-hoverable";
  if (field) fullClassName += " field";
  if (className) fullClassName += " " + className;
  return (
    <table className={fullClassName}>
      {children}
    </table>
  )
}

export default Table;
