import React from 'react';

function Tabs({children}) {
  return (
    <div className="tabs is-boxed">
      <ul>
        {children}
      </ul>
    </div>
  )
}

export default Tabs;
