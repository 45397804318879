import React from 'react';
import Tags from '../components/Tags';
import Tag from '../components/Tag';

function InvoiceTag({total, onClick, index, date, markedAsSent}) {
  return(
    <Tags addons>
    <Tag
      color="white"
      className={(!markedAsSent ? "is-semi-transparent is-link" : "is-link")}
      background="success"
      label="Invoice" />
    <Tag
      background="grey-lighter"
      color="dark"
      label={<span className="is-size-7">{index + "/" + total}</span>} />
    </Tags>
  )
}

export default InvoiceTag;
