import React from 'react';
import FormField from './FormField';
import DeleteButton from './DeleteButton';

function DynamicField({children, onDelete, type}) {
  const deleteButton = <DeleteButton label="" onClick={onDelete} />;
  if (type === "input") {
    return(
      <FormField variant="grouped">
        {children}
        {deleteButton}
      </FormField>
    )
  }
  else if (type === "tableRow" || type === "custom") {
    return children;
  }
}

export default DynamicField;
