import React from 'react';
import { Redirect } from 'react-router-dom'
import Loader from './Loader';
import Article from './Article';
import Divider from './Divider';
import Title from './Title';
import Subtitle from './Subtitle';
import FormField from './FormField';
import Label from './Label';
import TextInput from './TextInput';
import NumberInput from './NumberInput';
import CurrencySelector from './CurrencySelector';
import CustomerSelector from './CustomerSelector';
import DeliverablesFields from './DeliverablesFields';
import ModulesFields from './ModulesFields';
import CostSummary from './CostSummary';
import Column from './Column';
import Columns from './Columns';
import Dynamic from './Dynamic';
import Switch from './Switch';
import Form from './Form';
import Notification from './Notification';
import IterationTabs from './IterationTabs';
import IntroductionTextInput from './IntroductionTextInput'

class QuoteForm extends React.Component {

  constructor(props) {
    super(props);
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth()+1;
    const yyyy = today.getFullYear();
    this.requests = [];
    this.state = {
      title: (this.props.id ? "Edit quote" : "Create quote"),
      loading: true,
      customersLoaded: false,
      categoriesLoaded: false,
      quoteLoaded: (this.props.id ? false : true),
      redirectToQuotesList: false,
      customers: [],
      customer: null,
      projectTitle: "",
      date: yyyy + '-' + mm + '-' + dd,
      deadline: "",
      client: "",
      contact: "",
      currency: "GBP",
      vatRate: 20,
      rate: 360,
      introductionText: "",
      deliverables: [],
      iterations: [],
      modules: [],
      productionManagementCost: 0,
      productionManagementPercentage: 20,
      extraCosts: 0,
      extraCostsDescription: "",
      renderingCost: 0,
      discount: 0,
      discountPercentage: 0,
      displayIntroduction: true,
      displayAbout: true,
      displaySignature: true,
      categories: [],
      disabled: true,
      leavingPage: false,
      saved: true
    }
    this.categoriesCalculated = 0;
    this.customersLoaded = this.customersLoaded.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleIntroductionTextChange = this.handleIntroductionTextChange.bind(this)
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleDeliverablesChange = this.handleDeliverablesChange.bind(this);
    this.handleModulesChange = this.handleModulesChange.bind(this);
    this.handleModuleQuantityChange = this.handleModuleQuantityChange.bind(this);
    this.prepareFormData = this.prepareFormData.bind(this);
    this.redirectToQuotesList = this.redirectToQuotesList.bind(this);
    this.createNewIteration = this.createNewIteration.bind(this);
    this.switchIteration = this.switchIteration.bind(this);
    this.handleSucces = this.handleSucces.bind(this);
  }

  componentWillUnmount() {
    if (this.requests) this.props.server.cancelRequests(this.requests);
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.iteration !== this.props.iteration) this.load();
  }

  load() {
    this.modulesByCategory = [];
    this.categoriesProcessedOnLoad = false;
    if (this.props.id) {
      const component = this;
      component.setState({
        error: false,
        loading: true,
        saved: true
      })
      const callback = function(response) {
        if (response.error) {
          component.setState({
            error: response.data,
            loading: false,
          });
        }
        else {
          component.loadCategories();
          const data = response.data;
          const disabled = data.converted_to_invoice || (data.marked_as_sent && !component.props.newIteration);
          let reference = data.reference + data.reference_suffix;
          component.setState({
            marked_as_sent: data.marked_as_sent,
            converted_to_invoice: data.converted_to_invoice,
            reference: reference,
            convertedToInvoice: data.converted_to_invoice,
            iterationCount: data.iteration_count,
            quoteLoaded: true,
            customer: data.customer.id,
            projectTitle: data.project_title,
            date: data.date,
            deadline: data.deadline,
            client: data.client,
            contact: data.contact,
            currency: data.currency,
            rate: (!data.rate ? (data.currency === 'GBP' ? 360 : 500) : data.rate),
            vatRate: Number(data.vat_rate),
            introductionText: data.introduction_text,
            deliverables: data.deliverables,
            iterations: data.iterations,
            modules: data.modules,
            productionManagementCost: Number(data.production_management_cost),
            productionManagementPercentage: data.production_management_percentage,
            extraCosts: Number(data.extra_costs),
            extraCostsDescription: data.extra_costs_description,
            renderingCost: Number(data.rendering_cost),
            discount: Number(data.discount),
            discountPercentage: Number(data.discount_percentage),
            displayIntroduction: data.display_introduction,
            displayAbout: data.display_about,
            displaySignature: data.display_signature,
            disabled: disabled,
            active: true
          });
        }
      }
      this.requests.push(this.props.server.request(callback, 'get', 'quota/quote?id=' + component.props.id + "&iteration_id=" + component.props.iteration));
      }
      else {
        this.loadCategories();
        this.setState({
          title: "Create quote",
          disabled: false
        })
      }
  }

  loadCategories() {
    const component = this;
    const callback = function(response) {
      if (response.error) {
        component.setState({
          error: response.data,
          loading: false
        });
      }
      else {
        component.setState({
          categories: response.data,
          loading: false,
          categoriesLoaded: true
        });
      }
    }
    this.requests.push(this.props.server.request(callback, 'get', 'quota/category'));
  }

  customersLoaded(response) {
    this.setState({
      error: (!this.state.error ? (response.error ? response.data : false) : this.state.error),
      customersLoaded: response.error,
      customers: (response.error ? [] : response.data)
    });
  }

  prepareFormData() {
    const {customer, projectTitle, date, deadline, introductionText, client, contact, currency, vatRate, rate, renderingCost, extraCosts, extraCostsDescription, discount, productionManagementCost, displayIntroduction, displayAbout, displaySignature, modules, deliverables} = this.state;
    const data = {
      "kf_customer_id": customer,
      "project_title": projectTitle,
      "date": date,
      "deadline": deadline,
      "introduction_text": introductionText,
      "client": client,
      "contact": contact,
      "currency": currency,
      "vat_rate": vatRate,
      "rate": rate,
      "rendering_cost": renderingCost,
      "production_management_cost": productionManagementCost,
      "extra_costs": extraCosts,
      "extra_costs_description": extraCostsDescription,
      "discount": discount,
      "display_introduction": displayIntroduction,
      "display_about": displayAbout,
      "display_signature": displaySignature,
      "modules": modules,
      "deliverables": deliverables,
    }
    return data;
  }

  redirectToQuotesList() {
    this.setState({
      redirectToQuotesList: true
    })
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = (target.type === "checkbox" ? target.checked : target.value);
    this.setState({
      saved: false,
      [name] : value
    });
    if (name === "customer") {
      this.setState({
        contact: "",
      });
    }
    if (name === "productionManagementPercentage") {
      const productionManagementCost = this.calculateProductionManagementCost(this.state.modules, value);
      this.setState({
        productionManagementCost: productionManagementCost,
        discount: this.calculateDiscount(this.state.modules, this.state.discountPercentage, productionManagementCost, this.state.renderingCost, this.state.extraCosts)
      });
    }
    if (name === "renderingCost") {
      this.setState({
        discount: this.calculateDiscount(this.state.modules, this.state.discountPercentage, this.state.productionManagementCost, value, this.state.extraCosts)
      });
    }
    if (name === "discountPercentage") {
      this.setState({
        discount: this.calculateDiscount(this.state.modules, value, this.state.productionManagementCost, this.state.renderingCost, this.state.extraCosts)
      });
    }
    if (name === "extraCosts") {
      this.setState({
        discount: this.calculateDiscount(this.state.modules, this.state.discountPercentage, this.state.productionManagementCost, this.state.renderingCost, value)
      });
    }
    if (name === "rate") {
      const modules = this.resetLineRates(this.state.modules, value);
      const productionManagementCost = this.calculateProductionManagementCost(modules);
      const discount = this.calculateDiscount(modules, this.state.discountPercentage, productionManagementCost, this.state.renderingCost, this.state.extraCosts);
      this.setState({
        rate : value,
        modules: modules,
        productionManagementCost: productionManagementCost,
        discount: discount
      });
    }
    if (!target.type === "checkbox") event.preventDefault();
  }

  handleIntroductionTextChange(value) {
    this.setState({
      introductionText: value
    })
  }

  handleCurrencyChange(event) {
    this.handleInputChange(event);
    const rate = (event.target.value === 'GBP' ? 360 : 500);
    const modules = this.resetLineRates(this.state.modules, rate);
    const productionManagementCost = this.calculateProductionManagementCost(modules);
    const discount = this.calculateDiscount(modules, this.state.discountPercentage, productionManagementCost, this.state.renderingCost, this.state.extraCosts);
    this.setState({
      saved: false,
      vatRate : (event.target.value === 'GBP' ? 20 : 0),
      rate : rate,
      modules: modules,
      productionManagementCost: productionManagementCost,
      discount: discount
    });
  }

  handleDeliverablesChange(deliverables) {
    this.setState({
      saved: false,
      deliverables: deliverables
    });
  }

  handleModulesChange(modules, category) {
    const alphabet = [...Array(26).keys()].map(i => String.fromCharCode(i + 97).toUpperCase());
    if (!this.categoriesProcessedOnLoad && this.modulesByCategory.length < this.state.categories.length - 1) {
      this.modulesByCategory.push(modules);
    }
    else {
      let modulesWithoutThisCategory = [];
      if (!this.categoriesProcessedOnLoad) {
        for(let i = 0; i < this.modulesByCategory.length; i++) {
          for(let x = 0; x < this.modulesByCategory[i].length; x++) modulesWithoutThisCategory.push(this.modulesByCategory[i][x]);
        }
        this.categoriesProcessedOnLoad = true;
      }
      else {
        this.setState({ saved: false });
        modulesWithoutThisCategory = this.state.modules.filter( module => module.category !== category, module => module.total !== undefined );
      }
      const allModules = [...modules, ...modulesWithoutThisCategory];
      allModules.sort((a, b) => a.category - b.category);
      for (let i=0; i< allModules.length;i++) allModules[i].name = alphabet[i];
      const productionManagementCost = this.calculateProductionManagementCost(allModules);
      this.setState({
        modules: allModules,
        productionManagementCost: productionManagementCost,
        discount: this.calculateDiscount(allModules, this.state.discountPercentage, productionManagementCost, this.state.renderingCost, this.state.extraCosts)
      });
    }
  }

  resetLineRates(modules, rate) {
    for(let i=0; i < modules.length; i++) {
      let moduleRate = 0;
      for(let x=0; x < modules[i].lines.length; x++) {
        const quantity = modules[i].lines[x].quantity;
        const days = modules[i].lines[x].days;
        const total = days * quantity * rate;
        modules[i].lines[x].rate = rate;
        modules[i].lines[x].total = total;
        moduleRate = moduleRate + total;
      }
      modules[i].rate = moduleRate;
      modules[i].total = moduleRate * modules[i].quantity;
    }
    return modules;
  }

  calculateDiscount(modules, percentage, productionManagementCost, renderingCost, extraCosts) {
    if (percentage > 0) {
      let net = Number(productionManagementCost) + Number(renderingCost) + Number(extraCosts);
      for(let i=0; i < modules.length; i++) net += Number(modules[i].total);
      return net * (percentage / 100);
    }
    else return 0;
  }

  calculateProductionManagementCost(modules, percentage = "default") {
    if (percentage === "default") percentage = this.state.productionManagementPercentage;
    if (percentage > 0) {
      let total = 0;
      for(let i=0; i < modules.length; i++) {
        total += modules[i].total;
      }
      return total * (percentage / 100);
    }
    else return 0;
  }

  handleSucces() {
    if (this.props.id) {
      window.scrollTo(0, 0);
      this.setState({ saved: true });
    }
    else {
      this.setState({ redirectToQuotesList: true });
    }
  }

  switchIteration(id) {
    if (!this.state.saved) {
      if (window.confirm('Are you sure you want to switch iterations? Unsaved changes will be lost.')) {
        this.props.switchIteration(id);
      }
    }
    else {
      this.props.switchIteration(id);
    }
  }

  createNewIteration(event) {
    const {server, id} = this.props;
    const url = "quota/quote?action=update&id=" + id + "&return=id";
    const data = this.prepareFormData();
    const component = this;
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (Array.isArray(value)) formData.set(key, JSON.stringify(value));
        else formData.set(key, value);
      }
    }
    component.setState({
      error: false,
      loading: true
    })
    const callback = function(response) {
      component.setState({ loading: false });
      if (response.error) {
        component.setState({ error: response.data });
      }
      else {
        component.props.switchIteration(response.data);
      }
    }
    const request = server.request(callback, 'post', url, formData);
    this.requests.push(request);
    event.preventDefault();
  }

  handleModuleQuantityChange(event, key) {
    const modules = this.state.modules;
    const newQuantity = event.target.value;
    const newDays = modules[key]['days'] * newQuantity;
    modules[key].quantity = newQuantity;
    modules[key].totalDays = newDays;
    modules[key].total = newQuantity * modules[key].rate;
    const productionManagementCost = this.calculateProductionManagementCost(modules);
    this.setState({
      saved: false,
      modules: modules,
      productionManagementCost: productionManagementCost,
      discount: this.calculateDiscount(modules, this.state.discountPercentage, productionManagementCost, this.state.renderingCost, this.state.extraCosts)
    });
  }

  render() {
    if (this.state.redirectToQuotesList) {
      return <Redirect to="/quota/" />
    }
    else {
      const component = this;
      const {server, id, newIteration} = this.props;
      const action = (id ? (newIteration ? "update&id=" + id : "update&id=" + id + "&iteration_id=" + this.props.iteration ) : "create");
      const url = "quota/quote?action=" + action;
      const data = (this.state.loading ? null : this.prepareFormData());
      let ModulesFieldsSets = [];
      if (!this.state.loading && !this.state.error) {
        const modulesByCategory = {};
        for (let i=0; i < this.state.categories.length; i++) modulesByCategory[this.state.categories[i].id.toString()] = [];
        for (let i=0; i < this.state.modules.length; i++) modulesByCategory[this.state.modules[i].category.toString()].push(this.state.modules[i]);
        ModulesFieldsSets = this.state.categories.map((category, index) =>
          <div key={category.id}>
          <Article>
            <Subtitle label={category.name} />
            <ModulesFields
              category={category.id}
              key={index}
              rate={component.state.rate}
              modules={modulesByCategory[category.id.toString()]}
              onChange={component.handleModulesChange} />
          </Article>
          <Divider />
          </div>
        );
      }
      return(
        <div>
          <Notification danger>{this.state.error}</Notification>
          <Title label={this.state.title} />
          {this.state.loading && <Loader /> }

          <Dynamic visible={!this.state.loading}>
            <Form
              disabled={this.state.disabled}
              method="post"
              server={server}
              url={url}
              data={data}
              onSucces={this.handleSucces}
              onCancel={this.redirectToQuotesList}>
              {this.props.id &&
                <IterationTabs
                  newIterationButton={!this.state.convertedToInvoice}
                  onNewIteration={this.createNewIteration}
                  onChange={this.switchIteration}
                  id={this.props.iteration}
                  iterations={this.state.iterations}
                  active={this.state.reference} />
              }
              <div className={(this.state.disabled ? "disabled" : "")}>
              <Article>
                <Subtitle label="Basic settings" />
                <Columns>
                  <Column>
                    <FormField>
                      <Label text="Project title" />
                      <TextInput
                        name="projectTitle"
                        placeholder="Project title"
                        value={this.state.projectTitle}
                        onChange={this.handleInputChange} />
                    </FormField>
                    <FormField>
                      <Label text="Client" />
                      <TextInput
                        name="client"
                        placeholder="Client"
                        value={this.state.client}
                        onChange={this.handleInputChange} />
                    </FormField>
                    <FormField>
                      <Label text="Customer" />
                      <CustomerSelector
                        syncButton
                        contactSelector
                        customerEditor
                        disabled={this.state.iterationCount > 1}
                        server={this.props.server}
                        selected={this.state.customer}
                        contact ={this.state.contact}
                        customersLoaded={this.customersLoaded}
                        onChange={this.handleInputChange} />
                      </FormField>
                  </Column>
                  <Column>
                    <FormField>
                      <Label text="Date" />
                      <TextInput
                        name="date"
                        placeholder="YYYY-MM-DD"
                        value={this.state.date}
                        onChange={this.handleInputChange} />
                    </FormField>
                    <FormField>
                      <Label text="Deadline" />
                      <TextInput
                        name="deadline"
                        placeholder="YYYY-MM-DD"
                        value={this.state.deadline}
                        onChange={this.handleInputChange} />
                    </FormField>
                    <CurrencySelector
                      value={this.state.currency}
                      onChange={this.handleCurrencyChange}
                    />
                    <FormField>
                      <Label text="Rate" />
                      <NumberInput
                        name="rate"
                        placeholder="Rate"
                        value={this.state.rate}
                        onChange={this.handleInputChange} />
                    </FormField>
                  </Column>
                </Columns>
              </Article>
              <Divider />
              <Article>
                <Subtitle label="Layout settings" />
                <Columns>
                  <Column>
                    <FormField>
                      <Label text="Introduction page" />
                      <Switch
                        name="displayIntroduction"
                        checked={this.state.displayIntroduction}
                        onChange={this.handleInputChange} />
                    </FormField>
                  </Column>
                  <Column>
                    <FormField>
                      <Label text="About page" />
                      <Switch
                        name="displayAbout"
                        checked={this.state.displayAbout}
                        onChange={this.handleInputChange} />
                    </FormField>
                  </Column>
                  <Column>
                    <FormField>
                      <Label text="Signature page" />
                      <Switch
                        name="displaySignature"
                        checked={this.state.displaySignature}
                        onChange={this.handleInputChange} />
                    </FormField>
                  </Column>
                </Columns>
              </Article>
              <Divider />
              <Article>
                <Subtitle label="Introduction text" />
                  <FormField>
                    <p className="has-padding-bottom">
                      Dear {(this.state.contact ? this.state.contact : " ...")}
                    </p>
                    {!this.state.loading && !this.state.error && (
                      <IntroductionTextInput
                        placeholder="Introduction text"
                        name="introductionText"
                        disabled={!this.state.displayIntroduction}
                        html={this.state.introductionText}
                        onChange={this.handleIntroductionTextChange}
                      />
                    )}
                    <p className="has-padding-top">For and on behalf of Three Thirds,</p>
                    <p>{this.props.server.apiUser().name}</p>
                  </FormField>
              </Article>
              <Divider />
              <Article>
                <Subtitle label="Deliverables" />
                <DeliverablesFields
                  onChange={this.handleDeliverablesChange}
                  deliverables={this.state.deliverables}
                />
              </Article>
            <Divider />
              {!this.state.loading &&
                <div>
                  {ModulesFieldsSets}
                  <Article>
                    <Subtitle label="Cost summary" />
                    <CostSummary
                      modules={this.state.modules}
                      renderingCost={this.state.renderingCost}
                      productionManagementCost={this.state.productionManagementCost}
                      productionManagementPercentage={this.state.productionManagementPercentage}
                      extraCosts={this.state.extraCosts}
                      extraCostsDescription={this.state.extraCostsDescription}
                      discount={this.state.discount}
                      discountPercentage={this.state.discountPercentage}
                      vatRate={this.state.vatRate}
                      onModuleQuantityChange={this.handleModuleQuantityChange}
                      onChange={this.handleInputChange} />
                  </Article>
                </div>
              }
              </div>
              <Divider />
            </Form>
          </Dynamic>
        </div>
      )
    }
  }
}

export default QuoteForm;
