import React from 'react';
import TextInput from './TextInput';
import DeleteButton from './DeleteButton';
import FormField from './FormField';

function ContactsField(props) {
    const {name, email, onChange, onDeleteField} = props;
    return(
      <FormField variant="grouped">
          <TextInput
            name="name"
            value={name}
            placeholder="Name"
            onChange={(event) => onChange(event, "name")}
          />
          <TextInput
            name="email"
            expanded
            value={email}
            placeholder="Email"
            onChange={(event) => onChange(event, "email")} />
          <DeleteButton
            label=""
            onClick={onDeleteField} />
      </FormField>
    );
}

export default ContactsField;
