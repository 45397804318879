import React from 'react';
import TextInput from './TextInput';
import DeleteButton from './DeleteButton';
import TableCell from './TableCell';
import TableRow from './TableRow';
import CreateButton from './CreateButton';
import LineActions from './LineActions';
import LineAction from './LineAction';

function DeliverablesField(props) {
  const {description, deliveryFormat, frameRate, resolution, onCreateField, onDeleteField, onChange} = props;
  return(
      <TableRow>
        <TableCell>
          <TextInput
            name="description"
            onChange={onChange}
            placeholder="Description"
            value={description} />
        </TableCell>
        <TableCell>
          <TextInput
            name="delivery_format"
            onChange={onChange}
            placeholder="Delivery format"
            value={deliveryFormat} />
        </TableCell>
        <TableCell>
          <TextInput
            name="frame_rate"
            onChange={onChange}
            placeholder="Frame rate"
            value={frameRate} />
        </TableCell>
        <TableCell>
          <TextInput
            name="resolution"
            onChange={onChange}
            placeholder="Resolution"
            value={resolution} />
        </TableCell>
        <TableCell className="has-text-right table-cell--deliverables-actions">
          <LineActions>
            <LineAction>
              <CreateButton
                label=""
                onClick={onCreateField} />
            </LineAction>
            <LineAction>
              <DeleteButton
                label=""
                onClick={onDeleteField} />
            </LineAction>
          </LineActions>
        </TableCell>
      </TableRow>
  )
}

export default DeliverablesField;
