import React from 'react';
import Button from './Button';
import { Link } from 'react-router-dom';

function EditQuoteButton(props) {
  return (
    <Link to={"/quota/quote/" + props.id + "/iteration/" + props.iterationId}>
      <Button
        tooltip={(props.readOnly ? "View" : "Edit")}
        icon={(props.readOnly ? "eye" : "pencil-alt")} />
    </Link>
  );
}

export default EditQuoteButton;
