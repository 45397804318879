import React from 'react';
import Icon from '../components/Icon';

class LinkButton extends React.Component {

  render() {
    let label;
    let className = "is-inline-block button is-light";
    if (this.props.variant) className += " is-" + this.props.variant;
    if (this.props.color) className += " has-text-" + this.props.color;
    if (this.props.className) className += " " + this.props.className;
    if (this.props.icon) {
      label =
      <span>
        <Icon name={this.props.icon} color={this.props.color}/>
        {this.props.label &&
          <span className="has-margin-right">{this.props.label}</span>
        }
      </span>
    } else {
      label = this.props.label;
    }

    return (
      <a
        className={className}
        href={this.props.url}
        target={this.props.target}
        onClick={this.props.onClick}>
        {label}
      </a>
    )
  }
}

export default LinkButton;
