import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import 'bulma/css/bulma.min.css';
import 'bulma-switch/dist/css/bulma-switch.min.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './css/App.css';
import Container from './components/Container';
import NavigationBar from './components/NavigationBar';
import Section from './components/Section';
import Title from './components/Title';
import Login from './components/Login';
import AppLauncher from './components/AppLauncher';
import TrackaInvoices from './components/TrackaInvoices';
import Quotes from './components/Quotes';
import QuoteForm from './components/QuoteForm';
import EditQuote from './components/EditQuote';

function Router({server, authenticated, handleLogin, handleLogout}) {

  return(
    <HashRouter>

      <Switch>

        <Route exact path="/" render={props => (
          <Container>
            {!authenticated &&
              <Section>
                <Login
                  server={server}
                  onSuccess={handleLogin}
                />
              </Section>
            }
            {authenticated &&
              <Section>
                <AppLauncher />
              </Section>
            }
          </Container>
        )}/>

        <Route exact path={"/tracka/public/:customer/:username/:password/:customerName"} render={props => (
          <Container>
            <NavigationBar readonly/>
            <Section>
              <Title label={"Invoices (" + props.match.params.customerName + ")"} />
              <TrackaInvoices
                readonly
                customer={props.match.params.customer}
                username={props.match.params.username}
                password={props.match.params.password}
                server={server} />
            </Section>
          </Container>
        )}/>

        <Route path="/:appName" render={props =>(

          <div>
            {authenticated &&
              <NavigationBar
                server={server}
                loggedIn={authenticated}
                onLogout={handleLogout}
                userFullName={props.userFullName}
                currentApp = {props.match.params.appName}
              />
            }
            <Container>
                {!authenticated &&
                  <Section>
                    <Login
                      server={server}
                      onSuccess={handleLogin}
                    />
                  </Section>
                }
                {authenticated &&

                  <Switch>

                  <Route path="/tracka" render={props =>(

                    <Switch>

                    <Route exact path={props.match.url + "/"} render={props => (

                      <Section>
                        <Title label="Invoices" />
                        <TrackaInvoices server={server} />
                      </Section>

                    )}/>

                    <Route exact path={props.match.url + "/filter/:customer"} render={props => (
                      <Section>
                        <Title label="Invoices" />
                        <TrackaInvoices customer={props.match.params.customer} server={server} />
                      </Section>
                    )}/>

                    </Switch>

                  )}/>

                  <Route path="/quota" render={props =>(

                    <Switch>

                      <Route exact path={props.match.url + "/"} render={props => (

                        <Section>
                          <Title label="Quotes and invoices" />
                          <Quotes server={server} />
                        </Section>
                      )}/>

                      <Route exact path={props.match.url + "/filter/:customer/:customerName"} render={props => (
                        <Section>
                          <Title label={"Quotes and invoices (" + props.match.params.customerName + ")"} />
                          <Quotes customer={props.match.params.customer} server={server} />
                        </Section>
                      )}/>

                      <Route path={props.match.url + "/quote"} render={props =>(

                        <Switch>

                          <Route exact path={props.match.url + "/create"} render={props =>(
                            <Section>
                              <QuoteForm server={server} />
                            </Section>
                          )}/>

                          <Route path={props.match.url + "/:id/iteration/:iteration"} render={props =>(

                              <Section>
                                <EditQuote id={props.match.params.id} iteration={props.match.params.iteration} server={server} />
                              </Section>

                          )}/>

                      </Switch>
                      )}/>

                    </Switch>

                  )}/>

                </Switch>
              }
            </Container>
          </div>
        )}/>
      </Switch>

    </HashRouter>
  )
}

export default Router;
