import React from 'react'
import FormField from './FormField'
import Dropdown from './Dropdown'
import TextInput from './TextInput'
import Button from './Button'
import FormControl from './FormControl'
import Label from './Label'

export default function EmailAddressants(props) {

  const {
    value = [""],
    firstReadOnly,
    availableAddressants,
    onChange,
    disabled
  } = props

  const lastFieldIsEmpty = value.length > 0 ? !Boolean(value[value.length -1]) : true

  function addExistingAddressant(addressant) {
    const newValue = !lastFieldIsEmpty ? [...value, addressant.email] : value.map((email, index) => (
      index === value.length - 1 ? addressant.email : email
    ))
    onChange(newValue)
  }

  function addBlankAddressant(e) {
    onChange([...value, ""])
    e.preventDefault()
  }

  function removeAtIndex(index) {
    const newValue = [...value]
    newValue.splice(index, 1)
    onChange(newValue)
  }

  function inputChangeAtIndex(e, index) {
    const newValue = [...value]
    newValue[index] = e.target.value
    onChange(newValue)
    e.preventDefault()
  }

  

  //------
  // Rendering

  function render() {
    return (
      <div>
        {renderTo()}
      </div>
    )
  }

  function renderTo() {
    return (
      <div className="field">
        <Label text="Addressants"/>
        {value.map(renderAddressantLine)}
        <div className="has-text-right">
          {renderDropdown()}
        </div>
      </div>
    )
  }

  function renderAddressantLine(email, index) {
    const isFirst = index === 0
    if (isFirst && firstReadOnly) {
      return (
        <FormField addon='To:' prependAddon disabled={disabled}>
          <TextInput
            expanded
            value={email}
            disabled={disabled}
            readOnly
          />
        </FormField>
      )
    }
    return (
      <FormField variant='grouped' key={index} disabled={disabled}>
        <FormControl variant='expanded'>
          <TextInput 
            value={email}
            disabled={disabled}
            onChange={(e) => inputChangeAtIndex(e, index)}
          />
        </FormControl>
        <Button
          disabled={disabled || (isFirst && value.length === 1)}
          variant='danger'
          icon='trash-alt'
          color='white'
          className="has-margin-left"
          onClick={() => removeAtIndex(index)}
        />
      </FormField>
    )
  }

  function renderDropdown() {
    return (
      <Dropdown
        icon='plus'
        label={lastFieldIsEmpty && value.length <= 1 ? 'Add' : 'Add CC'}
        right
        hoverable={!disabled}
        onClick={addBlankAddressant}
        disabled={disabled}
      >
        {availableAddressants.filter(it => !value.includes(it)).map(addressant => (
          <AddressantDropdownItem
            key={`${addressant.name}${addressant.email}`}
            addressant={addressant}
            onAdd={addExistingAddressant}
          />
        ))}
      </Dropdown>
    )
  }

  return render()

}

function AddressantDropdownItem(props) {
  const {addressant, onAdd} = props

  function handleClick(e) {
    onAdd(addressant)
    e.preventDefault()
  }

  return (
    <a key={`${addressant.name}${addressant.email}`} className="dropdown-item" onClick={handleClick}>
      {formatAddressant(addressant.name, addressant.email)}
    </a>
  )

}

export function formatAddressant(name, email) {
  return `${name} <${email}>`
}