import React from 'react';
import Button from './Button';

class LogOutButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
    this.logout = this.logout.bind(this);
  }

  componentWillUnmount() {
    this.request && this.props.server.abortRequest(this.request);
  }

  logout(event) {
    const component = this;
    component.setState({loading: true, error: false})
    const callback = function(response) {
      component.setState({ loading: false });
      if (!response.error) component.props.onClick(event);
    }
    this.request = this.props.server.request(callback, 'get', 'logout');
    event.preventDefault();
  }

  render() {
    return(
      <Button
        loading={this.state.loading}
        icon="sign-out-alt"
        label="Log out"
        variant="link"
        color="white"
        onClick={this.logout}/>
    )
  }

}

export default LogOutButton;
