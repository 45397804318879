import React from 'react';

function QuoteAction(props) {
  return (
    <li className="is-inline-block has-margin-right has-margin-bottom">
      {props.children}
    </li>
  );
}

export default QuoteAction;
