import React from 'react';

function Level(props) {
  return (
    <div className="level">
      {props.children}
    </div>
  )
}

export default Level;
