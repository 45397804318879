import React from 'react';
import LinkButton from './LinkButton';
import Button from './Button';

function EditButton({className, url, label, onClick}) {
  const attributes = {
    className: className,
    icon: "pencil-alt",
    label: (label === undefined ? 'Edit' : label),
    onClick: onClick
  }
  if (url) {
    return <LinkButton url={url} {...attributes} />
  }
  else {
    return <Button {...attributes} />
  }

}

export default EditButton;
