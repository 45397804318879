import React from 'react';

function TableRow({children, backgroundColor, className, hasGap, ...props}) {
  let fullClassName = "table-cell";
  if (backgroundColor) fullClassName+= " has-background-" + backgroundColor;
  if (className) fullClassName += " " + className;
  const rows = [];
  if (hasGap) rows.push(<tr key="0" {...props}><td colSpan={children.length}></td></tr>);
  rows.push(<tr key="1" className={fullClassName} {...props}>
    {children}
  </tr>);

  return rows;

}

export default TableRow;
