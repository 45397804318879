import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import Icon from './Icon';
import Menu from './Menu';
import MenuHeader from './MenuHeader';
import MenuList from './MenuList';
import MenuListItem from './MenuListItem';

class AppSwitcher extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentApp !== this.props.currentApp) {
      this.closeMenu();
    }
  }

  closeMenu(event = null) {
    this.setState({open: false})
    if (event) event.preventDefault();
  }

  openMenu(event = null) {
    this.setState({open: true})
    if (event) event.preventDefault();
  }

  render() {
    const className = "app-switcher " + (this.state.open ? "app-switcher--open" : "app-switcher--closed");

    return(
      <div className="app-switcher-container">
        <Logo onClick={(e) => this.openMenu(e)} />
          <Menu className={className}>
            <MenuHeader>
              <Logo onClick={(e) => this.closeMenu(e)} />
            </MenuHeader>
            <MenuList>
              <MenuListItem className="site-name">
                <Link onClick={(e) => this.closeMenu()} to="/quota">
                  <Icon color="dark" name="file-invoice" />Quota
                </Link>
              </MenuListItem>
              <MenuListItem className="site-name">
                <Link onClick={(e) => this.closeMenu()} to="/tracka">
                  <Icon color="dark" name="wallet" />Tracka
                </Link>
              </MenuListItem>
            </MenuList>
          </Menu>
          {this.state.open &&
            <div
              onClick={(e) => this.closeMenu(e)}
              className="app-switcher-filler">
            </div>
          }
      </div>
    );
  }
}

export default AppSwitcher;
