import React from 'react';

function MenuHeader({children}) {
  return(
    <p className="menu-header">
      {children}
    </p>
  )
}

export default MenuHeader;
