import React from 'react';
import SubmitButton from './SubmitButton';
import CancelButton from './CancelButton';
import Level from '../components/Level';
import LevelLeft from '../components/LevelLeft';
import LevelRight from '../components/LevelRight';
import LevelItem from '../components/LevelItem';
import Notification from './Notification';
import FormField from './FormField';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loading: false,
      response: null
    }
    this.submit = this.submit.bind(this);
  }

  componentDidUpdate(previousProps) {
    if (previousProps.url !== this.props.url) this.setState({ response: null });
  }

  componentWillUnmount() {
    this.request && this.props.server.abortRequest(this.request);
  }

  submit(event) {
    const {server, method, url, data} = this.props;
    const component = this;
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (Array.isArray(value)) formData.set(key, JSON.stringify(value));
        else formData.set(key, value);
      }
    }
    component.setState({
      error: false,
      loading: true,
      response: null
    })
    const callback = function(response) {
      if (response.error) window.scrollTo(0,0);
      else component.props.onSucces(response.data);
      component.setState({
        error: response.error,
        loading: false,
        response: response.data
      });
    }
    const request = server.request(callback, method, url, formData);
    this.request = request;
  }

  render() {
    const {children, disabled} = this.props;
    return(
      <div>
        <Notification
          danger={this.state.error}
          succes={!this.state.error}>
            {this.state.response}
          </Notification>
        {children}
        {!disabled &&
          <FormField>
            <Level>
              <LevelLeft />
              <LevelRight>
                <LevelItem>
                  <SubmitButton onClick={this.submit} loading={this.state.loading} />
                </LevelItem>
                <LevelItem>
                  <CancelButton onClick={this.props.onCancel} />
                </LevelItem>
              </LevelRight>
            </Level>
          </FormField>
        }
      </div>
    )
  }
}

export default Form;
