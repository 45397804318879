import React from 'react';
import FormField from './FormField';
import Modal from './Modal';
import Button from './Button';
import FormControl from './FormControl';

class DownloadButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      link: null,
      copied: false
    }
    this.requests = [];
    this.download = this.download.bind(this);
    this.copyLink = this.copyLink.bind(this);
  }

  componentWillUnmount() {
    this.props.server.cancelRequests(this.requests);
    this.downloadRequest && this.props.server.abortDownload(this.downloadRequest);
  }

  download(event, attachment = false) {
    const component = this;
    const {url, server, onError} = this.props;
    if (attachment) {
      component.setState({downloading: true});
      const callback = function(response) {
        component.setState({
          downloading: false,
          error: response.error
        })
      }
      this.downloadRequest = component.props.server.download(callback, url + "&attachment=true");
    }
    else {
      component.setState({ loading: true });
      const callback = function(response) {
        component.setState({ loading: false });
        if (response.error) {
          onError(response.data);
        }
        else {
          component.setState({ link: response.data });
        }
      }
      this.requests.push(server.request(callback, 'get', url ));
    }
    if (event) event.preventDefault();
  }

  copyLink(event) {
    const copyFrom = document.createElement("textarea");
    document.body.appendChild(copyFrom);
    copyFrom.textContent = this.state.link;
    copyFrom.select();
    document.execCommand("copy");
    copyFrom.remove();
    this.setState({copied: true});
    event.preventDefault();
  }

  render() {
    const {disabled} = this.props;
    const {loading, link, copied, downloading} = this.state;
    return(
      <span>
      {link &&
        <Modal notification>
          <button onClick={(e) => this.setState({ link: null, copied: false })} className="delete"></button>
          <FormField variant="grouped is-grouped-centered">
            <FormControl>
              <Button
                onClick={(e) => this.download(e, true)}
                loading={downloading}
                icon="download"
                label="Save file"/>
            </FormControl>
            <FormControl>
              <Button
                tooltip={(copied) ? "Link copied to clipboard" : false}
                tooltipActive
                tooltipPosition="right"
                onClick={this.copyLink}
                icon="link"
                label="Copy link" />
            </FormControl>
          </FormField>
        </Modal>
      }
      <Button
        tooltip="Download"
        disabled={disabled}
        loading={loading}
        onClick={this.download}
        icon="download" />
      </span>
    )
  }
}

export default DownloadButton;
