import React from 'react';

function LevelItem(props) {
  return (
    <div className="level-item">
      {props.children}
    </div>
  )
}

export default LevelItem;
