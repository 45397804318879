import React from 'react';
import TableCell from './TableCell';
import Tag from '../components/Tag';
import InvoiceTag from '../components/InvoiceTag';
import LinkButton from './LinkButton';
import Button from './Button';
import PublishForm from './PublishForm';
import ConvertToInvoiceForm from './ConvertToInvoiceForm';
import Modal from './Modal';
import TableRow from './TableRow';
import PublishInvoiceButton from './PublishInvoiceButton';
import QuoteHistory from './QuoteHistory';
import DownloadButton from './DownloadButton';
import ApproveButton from './ApproveButton';
import QuoteActions from './QuoteActions';
import QuoteAction from './QuoteAction';
import EditQuoteButton from './EditQuoteButton';
import QuoteCustomer from './QuoteCustomer';
import Dropdown from './Dropdown';
import DuplicateButton from './DuplicateButton';

class Quote extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
      publishing: false,
      showInvoices: false,
      convertingToInvoice: false,
      syncingWithKashflow: false,
      showHistory: false
    }
    this.requests = [];
    this.syncWithKashflow = this.syncWithKashflow.bind(this);
    this.deleteQuote = this.deleteQuote.bind(this);
    this.copyQuote = this.copyQuote.bind(this);
    this.showPublishForm = this.showPublishForm.bind(this);
    this.showConvertToInvoiceForm = this.showConvertToInvoiceForm.bind(this);
    this.handleSuccesfullySubmittedForm = this.handleSuccesfullySubmittedForm.bind(this);
    this.hideForms = this.hideForms.bind(this);
    this.toggleHistory = this.toggleHistory.bind(this);
    this.showPublishInvoiceForm = this.showPublishInvoiceForm.bind(this);
  }

  componentWillUnmount() {
    if (this.requests) for (let i=0; i < this.request; i++) this.props.server.abortRequest(this.requests[i]);
  }

  toggleHistory(event) {
    this.setState({
      showInvoices: false,
      showHistory: !this.state.showHistory
    })
    event.preventDefault();
  }

  showPublishForm(event) {
    const quote = this.props.data;
    if (quote.converted_to_invoice) {
      if (quote.invoices.length > 1) {
        this.setState({
          showHistory: false,
          showInvoices: !this.state.showInvoices
        });
      }
      else {
        this.setState({
          publishingInvoice: true
        });
      }
    }
    else {
      this.setState({
        publishing: true,
      });
    }
    event.preventDefault();
  }

  handleSuccesfullySubmittedForm(data) {
    this.hideForms();
    this.props.onSucces(data);
  }

  showPublishInvoiceForm(event) {
    this.setState({
      publishingInvoice: true
    })
    event.preventDefault();
  }

  showConvertToInvoiceForm(event) {
    this.setState({
      convertingToInvoice: true
    })
    event.preventDefault();
  }

  hideForms(event = null) {
    this.setState({
      approving: false,
      deleting: false,
      publishingInvoice: false,
      publishing: false,
      convertingToInvoice: false,
    })
    if (event) event.preventDefault();
  }

  copyQuote(event, quoteId) {
    const component = this;
    component.props.handleLoading(true);
    const callback = function(response) {
      component.props.handleLoading(false);
      if (response.error) component.props.onError(response.data);
      else component.props.onSucces(response.data);
    }
    this.requests.push(this.props.server.request(callback, 'post', 'quota/quote?action=copy&id=' + quoteId));
    event.preventDefault();
  }

  syncWithKashflow(event, quoteId) {
    const component = this;
    component.setState({syncingWithKashflow: true});
    component.props.handleLoading(true);
    const callback = function(response) {
      component.props.handleLoading(false);
      component.setState({syncingWithKashflow: false});
      if (response.error) component.props.onError(response.data);
      else component.props.onSucces(response.data);
    }
    this.requests.push(this.props.server.request(callback, 'post', 'quota/quote?action=sync_with_kashflow&id=' + quoteId + '&iteration_id=' + this.props.data.iteration_id));
    event.preventDefault();
  }

  deleteQuote(event, quoteId) {
    if (window.confirm('Are you sure you want to delete this quote and all it\'s iterations?')) {
      const component = this;
      component.props.handleLoading(true);
      const callback = function(response) {
        component.props.handleLoading(false);
        if (response.error) component.props.onError(response.data);
        else component.props.onSucces(response.data);
      }
      this.requests.push(this.props.server.request(callback, 'delete', 'quota/quote?id=' + quoteId));
    }
    event.preventDefault();
  }

  render() {
      const quote = this.props.data;
      const invoiceIsUnsent = quote.invoices_to_send > 0;
      const invoiceIndex = (quote.invoices_to_send ? quote.invoices_to_send -1 : 0);
      let number = quote.number;
      let author = quote.author;
      let reference = quote.reference + quote.reference_suffix;
      if (quote.converted_to_invoice) {
        author = (invoiceIsUnsent ? author = "—" : quote.invoice_author)
        number = (invoiceIsUnsent ? number = "—" : quote.invoice_id);
        reference = (invoiceIsUnsent ? reference = "" : quote.purchase_order)
      }
      return(
        <tbody>
          <TableRow>
            <TableCell>{number}</TableCell>
            <TableCell>{reference}</TableCell>
            <TableCell>{author}</TableCell>
            <TableCell heading>{quote.project_title}</TableCell>
            <TableCell>
              <QuoteCustomer
                id={quote.customer.id}
                name={quote.customer.name}
                link={this.props.customerLink} />
            </TableCell>
            <TableCell className="table-cell--status">
              {!quote.marked_as_sent &&
                <Tag label="Draft" />
              }
              {quote.marked_as_sent && !quote.approved && !quote.converted_to_invoice &&
                <Tag color="white" background="info" label={"Sent"} />
              }
              {quote.marked_as_sent && quote.approved && !quote.converted_to_invoice &&
                <Tag color="white" background="primary" label={"Approved"} />
              }
              {quote.converted_to_invoice &&
                <InvoiceTag
                  index={quote.invoices[invoiceIndex].index}
                  markedAsSent={quote.invoices[invoiceIndex].marked_as_sent}
                  date={quote.invoices[invoiceIndex].date}
                  total={quote.invoices.length}
                />
              }
            </TableCell>
            <TableCell className="has-text-right table-cell--actions">
              {quote.kashflow_warning &&
                <Button
                  variant="warning"
                  onClick={(event) => this.syncWithKashflow(event, quote.id)}
                  icon="exclamation-triangle"
                  color="dark"
                  loading={this.state.syncingWithKashflow}
                  label="Sync with Kashflow" />
              }
              {!quote.kashflow_warning &&
              <QuoteActions>
                <QuoteAction>
                  <Button
                    tooltip="History"
                    disabled={!quote.converted_to_invoice && quote.iteration_count < 2}
                    icon="history"
                    onClick={this.toggleHistory} />
                </QuoteAction>
                <QuoteAction>
                  <DownloadButton
                    server={this.props.server}
                    disabled={quote.converted_to_invoice && !quote.invoices[0].marked_as_sent}
                    onError={this.props.onError}
                    url={(quote.converted_to_invoice ? 'quota/invoice?action=download&id=' + quote.invoices[0].id : 'quota/iteration?action=download&id=' + quote.iteration_id)} />
                </QuoteAction>
                <QuoteAction>
                  <EditQuoteButton
                    id={quote.id}
                    iterationId={quote.iteration_id}
                    readOnly={quote.converted_to_invoice || quote.marked_as_sent} />
                </QuoteAction>
                <QuoteAction>
                  <Dropdown active={this.state.publishing || this.state.convertingToInvoice || this.state.publishingInvoice || this.state.approving} right hoverable icon="ellipsis-h">
                    <QuoteActions className='dropdown-item'>
                      <QuoteAction>
                        <DuplicateButton label="" tooltip="Copy" onClick={(event) => this.copyQuote(event, quote.id)} />
                      </QuoteAction>
                      {this.state.convertingToInvoice &&
                        <Modal onClose={this.hideForms}>
                          <ConvertToInvoiceForm
                            id={quote.id}
                            iterationId={quote.iteration_id}
                            server={this.props.server}
                            onCancel={this.hideForms}
                            onSucces={this.handleSuccesfullySubmittedForm} />
                        </Modal>
                      }
                      <QuoteAction>
                        <Button
                          tooltip="Convert to invoice"
                          icon="sync"
                          disabled={quote.converted_to_invoice || !quote.approved}
                          onClick={this.showConvertToInvoiceForm} />
                      </QuoteAction>
                      <QuoteAction>
                        <ApproveButton
                          id={quote.iteration_id}
                          quoteId={quote.id}
                          server={this.props.server}
                          onSucces={this.handleSuccesfullySubmittedForm}
                          onClick={(e) => this.setState({ approving: true })}
                          onError={this.props.onError}
                          disabled={!quote.marked_as_sent || quote.approved} />
                      </QuoteAction>
                      {this.state.publishing &&
                        <Modal onClose={this.hideForms}>
                          <PublishForm
                            id={quote.id}
                            subject={quote.project_title + " - " + reference}
                            message={"<p>Dear " + quote.contact + "</p>" + quote.introduction_text + "<p>For and on behalf of Three Thirds,<br />" + this.props.server.apiUser().name + "</p>"}
                            iterationId={quote.iteration_id}
                            server={this.props.server}
                            onCancel={this.hideForms}
                            onSucces={this.handleSuccesfullySubmittedForm} />
                        </Modal>
                      }
                      <QuoteAction>
                        {(!quote.converted_to_invoice || !invoiceIsUnsent) &&
                          <Button
                            tooltip="Publish"
                            disabled={(quote.marked_as_sent && !quote.converted_to_invoice) || (quote.converted_to_invoice && !invoiceIsUnsent)}
                            icon="envelope"
                            onClick={this.showPublishForm} />
                        }
                        {quote.converted_to_invoice && invoiceIsUnsent &&
                          <PublishInvoiceButton
                            invoice={quote.invoices[invoiceIndex]}
                            index={quote.invoices[invoiceIndex].index}
                            total={quote.invoices.length}
                            server={this.props.server}
                            onClick={this.showPublishInvoiceForm}
                            onSucces={this.handleSuccesfullySubmittedForm}
                            onCancel={this.hideForms}
                          />
                        }
                      </QuoteAction>
                      <QuoteAction>
                        <LinkButton
                          onClick={(event) => this.deleteQuote(event, quote.id)}
                          variant="danger"
                          icon="trash-alt"
                          color="white" />
                      </QuoteAction>
                    </QuoteActions>
                  </Dropdown>
                </QuoteAction>

              </QuoteActions>
            }
            </TableCell>
          </TableRow>
          {this.state.showHistory &&
            <QuoteHistory
              id={quote.id}
              skipLatest={!quote.converted_to_invoice}
              invoicesSent={!invoiceIsUnsent}
              quoteNumber={quote.number}
              invoices={quote.invoices}
              iterations={quote.iterations}
              server={this.props.server}
              onError={this.props.onError}
              onSucces={this.handleSuccesfullySubmittedForm}
              onCancel={this.hideForms}
            />
          }
        </tbody>
      )
  }
}

export default Quote;
