import React from 'react';
import Tab from './Tab';

function IterationTab({id, editable, reference, onClick, active}) {
  return(
    <Tab
      active={active}
      name={id}
      label={reference}
      onClick={onClick}
      icon={(editable ? "pencil-alt" : "eye")} />
  )
}

export default IterationTab;
