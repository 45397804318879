import React from 'react';
import Modal from '../components/Modal';
import SendRemindersButton from '../components/SendRemindersButton';
import SendRemindersForm from '../components/SendRemindersForm';

class SendReminders extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sendingReminders: false
    }
    this.requests = [];
    this.showReminderSender = this.showReminderSender.bind(this);
    this.hideReminderSender = this.hideReminderSender.bind(this);
  }

  componentWillUnmount() {
    if (this.requests) for (let i=0; i < this.request; i++) this.props.server.abortRequest(this.requests[i]);
  }

  showReminderSender(event) {
    this.setState({ sendingReminders: true });
    event.preventDefault();
  }

  hideReminderSender(event = null) {
    this.setState({ sendingReminders: false });
    if (event) event.preventDefault();
  }

  render() {
    const button = <SendRemindersButton
      onClick={this.showReminderSender}
      icon="bell"
      label="Send reminders" />
    if (this.state.sendingReminders) {
      return(
        [
          button,
          <Modal onClose={this.hideReminderSender}>
            <SendRemindersForm
              customer={this.props.customer}
              server={this.props.server}
              onSucces={this.props.onSucces}
              onCancel={this.hideReminderSender}
              invoices={this.props.invoices}
            />
          </Modal>
        ]
      )
    }
    else return button;
  }
}

export default SendReminders;
