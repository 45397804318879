import React from 'react';

function MenuListItem({children, className}) {
  return(
    <li className={className}>
      {children}
    </li>
  )
}

export default MenuListItem;
