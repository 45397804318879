import React from 'react';
import TextInput from './TextInput';
import DeleteButton from './DeleteButton';
import TableCell from './TableCell';
import TableRow from './TableRow';
import CreateButton from './CreateButton';
import NumberInput from './NumberInput';
import DragHandle from './DragHandle';
import numberWithCommas from './../functions/numberWithCommas.js';
import DuplicateButton from './DuplicateButton';
import LineActions from './LineActions';
import LineAction from './LineAction';

function ModulesField(props) {
  if (props.type === "line") {
    const {index, description, details, days, quantity, rate, total, onLineChange, onDuplicateLine, onDeleteLine} = props;
    return(
      <TableRow backgroundColor="light" data-index={index}>
        <TableCell>
          <DragHandle />
        </TableCell>
        <TableCell></TableCell>
        <TableCell wide>
          <TextInput
            name={"description"}
            value={description}
            placeholder="Description"
            onChange={(event) => onLineChange(event, 'description')}
          />
        </TableCell>
        <TableCell wide>
          <TextInput
            name={"details"}
            value={details}
            placeholder="Details"
            onChange={(event) => onLineChange(event, 'details')} />
        </TableCell>
        <TableCell narrow>
          <NumberInput
            name={"days"}
            value={days}
            placeholder="Days"
            onChange={(event) => onLineChange(event, 'days')}
          />
        </TableCell>
        <TableCell narrow>
          <NumberInput
            name={"quantity"}
            value={quantity}
            placeholder="Qty"
            onChange={(event) => onLineChange(event, 'quantity')}
          />
        </TableCell>
        <TableCell narrow>
          <NumberInput
            name={"rate"}
            value={rate}
            placeholder="Rate"
            onChange={(event) => onLineChange(event, 'rate')}
          />
        </TableCell>
        <TableCell narrow>
          <NumberInput
            value={numberWithCommas(total)}
            disabled
          />
        </TableCell>
        <TableCell className="has-text-right table-cell--line-actions">
          <LineActions>
            <LineAction>
            <DuplicateButton
              label=""
              onClick={onDuplicateLine} />
            </LineAction>
            <LineAction>
              <DeleteButton
                label=""
                onClick={onDeleteLine} />
              </LineAction>
          </LineActions>
        </TableCell>
      </TableRow>
    )
  } else {
    const {index, name, description, details, onChange, total, hasGap, onCreateLine, onDuplicateField, onDeleteField} = props;
    return(
        <TableRow className="module" data-index={index} hasGap={hasGap}>
          <TableCell>
            <DragHandle />
          </TableCell>
          <TableCell narrow heading>
            <TextInput
              readOnly
              bold
              isStatic
              value={name} />
          </TableCell>
          <TableCell wide>
            <TextInput
              name="description"
              bold
              value={description}
              placeholder="Description"
              onChange={(event) => onChange(event, 'description')}
            />
          </TableCell>
          <TableCell wide>
            <TextInput
              name="details"
              value={details}
              placeholder="Details"
              onChange={(event) => onChange(event, 'details')} />
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <NumberInput
              value={numberWithCommas(total)}
              disabled
            />
          </TableCell>
          <TableCell colspan="2" className="has-text-right table-cell--module-actions">
            <LineActions>
              <LineAction>
              <CreateButton
                label="Add line"
                onClick={onCreateLine}/>
              </LineAction>
              <LineAction>
              <DuplicateButton
                label=""
                onClick={onDuplicateField} />
              </LineAction>
              <LineAction>
                <DeleteButton
                  label=""
                  onClick={onDeleteField} />
                </LineAction>
            </LineActions>
          </TableCell>
        </TableRow>
      );
  }
}

export default ModulesField;
