import React from 'react';

function Tags({addons, children}) {
  return(
    <div className={(addons ? "tags has-addons" : "tags")}>
      {children}
    </div>
  )
}

export default Tags;
