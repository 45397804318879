import React from 'react';
import Invoice from './Invoice';

class SentInvoices extends React.Component {

  render() {
    const component = this;
    const sentInvoices = [];
    let max = this.props.invoices.length;
    let i=0;
    if (this.props.skipLatest) i = 1;
    for (i; i < max; i++) {
      if (this.props.invoices[i].marked_as_sent) sentInvoices.push(this.props.invoices[i]);
    }
    const invoices = sentInvoices.map((invoice, index) =>
        <Invoice
          key={invoice.id}
          index={invoice.index}
          total={component.props.invoices.length}
          invoices={component.props.invoices}
          server={component.props.server}
          onError={component.props.onError}
          onSucces={component.props.onSucces}
          onCancel={component.props.onCancel}
          data={invoice} />
    );
    return invoices;
  }

}

export default SentInvoices;
