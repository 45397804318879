import React from 'react';

function Subtitle(props) {
  return (
    <h3 className={"subtitle " + props.className}>
      {props.label}
    </h3>

  )
}

export default Subtitle;
