import React from 'react';

function LevelLeft(props) {
  return (
    <div className="level-left">
      {props.children}
    </div>
  )
}

export default LevelLeft;
