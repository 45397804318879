import React from 'react';
import FormControl from './FormControl.js';

function Select({variant, name, disabled, value, options, placeholder, onChange}) {
const Options = options.map((option, index) =>
  <option
    key={option.key}
    value={option.value}>
    {option.label}
  </option>
);
return (
    <FormControl>
      <div className={"select is-full-width " + (variant ? "is-" + variant : null)}>
        <select name={name} disabled={disabled} onChange={onChange} value={(value ? value : "default")}>
          {placeholder &&
            <option disabled value="default">{placeholder}</option>
          }
          {Options}
        </select>
      </div>
    </FormControl>
)
}

export default Select;
