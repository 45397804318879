import React from 'react';
import Button from './Button';

function DeleteButton({className, label, onClick}) {
  return (
    <Button
      type="button"
      variant="danger"
      color="white"
      className={className}
      icon="trash-alt"
      label={(label === undefined ? 'Delete': label)}
      onClick={onClick} />
  );

}

export default DeleteButton;
