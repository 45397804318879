import React from 'react';
import TableCell from './TableCell';
import TableRow from './TableRow';
import Right from './Right';
import InvoiceTag from '../components/InvoiceTag';
import DownloadButton from './DownloadButton';
import QuoteActions from './QuoteActions';
import QuoteAction from './QuoteAction';

class Iteration extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
      publishing: false
    }
  }

  render() {
    const { data, index, total } = this.props;
    return(
      <TableRow backgroundColor="light">
        <TableCell>{data.kashflow_id}</TableCell>
        <TableCell>{data.purchase_order}</TableCell>
        <TableCell>{data.author}</TableCell>
        <TableCell>—</TableCell>
        <TableCell>—</TableCell>
        <TableCell>
            <InvoiceTag total={total} markedAsSent={data.marked_as_sent} index={index} date={data.date} />
        </TableCell>
        <TableCell>
          <Right>
            <QuoteActions>
              <QuoteAction>
                <DownloadButton
                  server={this.props.server}
                  disabled={!data.marked_as_sent}
                  url={'quota/invoice?action=download&id=' + data.id} />
              </QuoteAction>
            </QuoteActions>
          </Right>
        </TableCell>
      </TableRow>
    )
  }

}
export default Iteration;
