import React from 'react';
import NextPage from '../components/NextPage';
import PreviousPage from '../components/PreviousPage';

function Pagination(props) {

  function handlePageChange(e, props, targetPage) {
    props.onPageChange(targetPage);
    e.preventDefault();
  }

  return(
    <nav className="pagination" aria-label="pagination">
      {props.currentPageNumber < props.totalPages &&
        <NextPage onClick={(e) => handlePageChange(e, props, props.currentPageNumber + 1)} />
      }
      {props.currentPageNumber > props.totalPages &&
        <div className="pagination-next invisible"></div>
      }
      {props.currentPageNumber > 0 &&
        <PreviousPage onClick={(e) => handlePageChange(e, props, props.currentPageNumber - 1)} />
      }
      {props.currentPageNumber === 0 &&
        <div className="pagination-previous invisible"></div>
      }
    </nav>
  );
}

export default Pagination;
