import React from 'react';

function HelpText({children, className}) {
  return (
    <p className={"help " + className}>
      {children}
    </p>
  )
}

export default HelpText;
