import React from 'react';
import DynamicFields from './DynamicFields';
import ModulesField from './ModulesField';
import Table from './Table';
import TableCell from './TableCell';
import TableRow from './TableRow';

class ModulesFields extends React.Component {
  constructor(props) {
    super(props);
    this.lines = [];
    this.alphabet = [...Array(26).keys()].map(i => String.fromCharCode(i + 97).toUpperCase());
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleCreateField = this.handleCreateField.bind(this);
    this.handleDeleteField = this.handleDeleteField.bind(this);
    this.collectLines = this.collectLines.bind(this);
    this.getModulesWithTotals = this.getModulesWithTotals.bind(this);
    this.onDragAndDrop = this.onDragAndDrop.bind(this);
  }

  componentDidMount() {
    this.props.onChange(this.getModulesWithTotals(this.props.modules), this.props.category);
  }

  collectLines() {
    const lines = [];
    let key = 100;
    for(let i=0; i < this.props.modules.length;i++) {
      const module = this.props.modules[i];
      const moduleKeyInLinesArray = lines.length;
      let moduleTotal = 0;
      lines.push({
        type: "module",
        category: this.props.category,
        moduleKey: i,
        key: i,
        lineKey: false,
        quantity: Number(module.quantity),
        ...module
      });

      for(let x=0; x < module.lines.length; x++) {
        const line = this.props.modules[i]['lines'][x];
        const lineTotal = Number(line.rate) * Number(line.days) * Number(line.quantity);
        lines.push({
          type: "line",
          moduleKey: i,
          lineKey: x,
          key: key++,
          total: lineTotal,
          rate: line.rate,
          days: line.days,
          ...line
        });
        moduleTotal += lineTotal;
      }
      moduleTotal = moduleTotal * module.totalDays;
      lines[moduleKeyInLinesArray]['total'] = moduleTotal;
    }
    return lines;
  }

  handleFieldChange(event, key, name) {
    const modules = this.props.modules;
    modules[key][name] = event.target.value;
    this.props.onChange(this.getModulesWithTotals(modules), this.props.category);
    event.preventDefault();
  }

  handleDeleteField(key, event = false) {
    const modules = this.props.modules;
    modules.splice(key, 1);
    this.props.onChange(this.getModulesWithTotals(modules), this.props.category);
    if (event) event.preventDefault();
  }

  handleDuplicateField(key, event) {
    const modules = this.props.modules;
    const newModules = [];
    for (let i=0; i < modules.length; i++) {
      if (i === key) {
        newModules.push({type: "module", ...modules[i]});
        newModules[newModules.length - 1].lines = [];
        for (let x=0; x < modules[i].lines.length; x++) newModules[newModules.length - 1].lines.push({type: "line", ...modules[i].lines[x]})
      }
      newModules.push({type: "module", ...modules[i]});
    }
    this.props.onChange(this.getModulesWithTotals(newModules), this.props.category);
    event.preventDefault();
  }

  handleDuplicateLine(event, key, lineKey) {
    const modules = this.props.modules;
    const newLines = [];
    for (let i=0; i < modules[key]['lines'].length; i++) {
      if (i === lineKey) newLines.push(modules[key]['lines'][i]);
      newLines.push({type: "line", ...modules[key]['lines'][i]});
    }
    modules[key]['lines'] = newLines;
    this.props.onChange(this.getModulesWithTotals(modules), this.props.category);
    event.preventDefault();
  }

  handleCreateField(key) {
    const modules = this.props.modules;
    modules.push({
      order: key,
      name: this.alphabet[key],
      rate: 0,
      days: 0,
      totalDays: 0,
      quantity: 1,
      description: "",
      details: "",
      total: 0,
      lines: [],
      category: this.props.category
    });
    this.props.onChange(this.getModulesWithTotals(modules), this.props.category);
  }

  handleCreateLine(key) {
    const modules = this.props.modules;
    modules[key]['lines'].push({
      order: key,
      description: "",
      details: "",
      days: 0,
      quantity: 0,
      rate: this.props.rate,
    });
    this.props.onChange(this.getModulesWithTotals(modules), this.props.category);
  }

  handleDeleteLine(event, moduleKey, lineKey) {
    const modules = this.props.modules;
    modules[moduleKey]['lines'].splice(lineKey, 1);
    this.props.onChange(this.getModulesWithTotals(), this.props.category);
    event.preventDefault();
  }

  handleLineChange(event, moduleKey, lineKey, name) {
    const modules = this.props.modules;
    modules[moduleKey]['lines'][lineKey][name] = event.target.value;
    this.props.onChange(this.getModulesWithTotals(modules), this.props.category);
    event.preventDefault();
  }

  getModulesWithTotals(modules = this.props.modules) {
    const alphabet = this.alphabet;
    const modulesWithTotals = [];

    for(let i=0; i < modules.length; i++) {

      let rate = 0;
      let days = 0;
      const module = modules[i];
      for (let x=0; x < module.lines.length; x++) {
        const line = module.lines[x];
        line.total = Number(line.rate) * Number(line.days) * Number(line.quantity);
        rate += Number(line.rate) * Number(line.days) * Number(line.quantity);
        days += Number(line.days) * Number(line.quantity);
      }

      const quantity = Number(module.quantity);
      const total = Number(rate * quantity);

      modulesWithTotals.push({
        type: "module",
        ...module,
        total: total ,
        rate: rate,
        days: days,
        totalDays: days * quantity,
        name: alphabet[i]
      });
    }
    return modulesWithTotals;
  }

  onDragAndDrop(newPositions) {
    const modules = this.props.modules;

    // make a new one-level array with all modules and all lines
    const beginModulesAndLines = [];
    for (let i = 0; i < modules.length; i++) {
      beginModulesAndLines.push({type: "module", ...modules[i]});
      for (let x = 0; x < modules[i].lines.length; x++) {
        beginModulesAndLines.push({type: "line", ...modules[i].lines[x]});
      }

    }

    // make a new one-level array with all modules lines in their new positions
    const endModulesAndLines = [];
    for (let fieldKey=0; fieldKey < newPositions.length; fieldKey++) {
      endModulesAndLines.push(beginModulesAndLines[newPositions[fieldKey]]);
    }

    if (endModulesAndLines[0].type !== "line") {
      // make array with the same structure as this.props.modules
      const newModules = [];
      for (let lineKey = 0; lineKey < endModulesAndLines.length; lineKey++) {
        const line = endModulesAndLines[lineKey];
        if (line.type === "module") {
          line.lines = [];
          newModules.push(line);
        }
        else {
          const currentModule = newModules.length - 1;
          newModules[currentModule].lines.push(line);
        }
      }

      this.props.onChange(this.getModulesWithTotals(newModules), this.props.category);
    }
  }

  render() {
    const component = this;
    const alphabet = this.alphabet;
    const newKey = component.props.modules.length;
    const newName = alphabet[newKey];
    const module = <ModulesField
      type="module"
      key={newKey}
      quantity="0"
      name={newName}
      description=""
      onChange={(event) => component.handleModuleChange(event, newKey)}
      onCreateLine={(event) => component.handleCreateLine(newKey)} />
    const modules = this.collectLines().map((module, index) =>
      <ModulesField
        type={module.type}
        moduleKey={module.moduleKey}
        key={(module.type === "module" ? module.moduleKey : module.key)}
        index={index}
        quantity={module.quantity}
        name={module.name}
        description={module.description}
        details={module.details}
        rate={module.rate}
        days={module.days}
        total={(module.type === "line" ? module.total : module.rate)}
        hasGap={module.type === "module" && newKey > 1 && index !== 0}
        onChange={(event, name) => component.handleFieldChange(event, module.moduleKey, name)}
        onCreateLine={(event) => component.handleCreateLine(module.moduleKey)}
        onDeleteField={(event) => component.handleDeleteField(module.moduleKey, event)}
        onDeleteLine={(event) => component.handleDeleteLine(event, module.moduleKey, module.lineKey)}
        onLineChange={(event, name) => component.handleLineChange(event, module.moduleKey, module.lineKey, name)}
        onDuplicateLine={(event) => component.handleDuplicateLine(event, module.moduleKey, module.lineKey)}
        onDuplicateField={(event) => component.handleDuplicateField(module.moduleKey, event)}
      />

    );
    return(
        <Table fullwidth>
          {this.props.modules.length > 0 &&
            <thead>
              <TableRow>
                <TableCell narrow></TableCell>
                <TableCell heading narrow>Module</TableCell>
                <TableCell heading wide>Description</TableCell>
                <TableCell heading wide>Details</TableCell>
                <TableCell heading narrow>Days</TableCell>
                <TableCell heading narrow>Qty</TableCell>
                <TableCell heading narrow>Unit cost</TableCell>
                <TableCell heading narrow>Total</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </thead>
          }
          <DynamicFields
            dragAndDrop
            onDragAndDrop={this.onDragAndDrop}
            fields={modules}
            field={module}
            fieldType="tableRow"
            id="content-production"
            columns="9"
            fieldSingleName="module"
            onCreateField={this.handleCreateField}
            onDeleteField={this.handleDeleteField} />
      </Table>
    );
  }
}

export default ModulesFields;
