import React from 'react';
import Subtitle from './Subtitle';
import NumberInput from './NumberInput';
import Label from './Label';
import FormField from './FormField';
import Divider from './Divider';
import Form from './Form';
import Notification from './Notification';
import Dynamic from './Dynamic';
import Loader from './Loader';
import FormControl from './FormControl';
import HelpText from './HelpText';

class ConvertToInvoiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parts: 1,
      loading: false,
      error: false,
      invoices: []
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = (target.type === "checkbox" ? target.checked : target.value);
    this.setState({
      [name] : value
    });
    if (!target.type === "checkbox") event.preventDefault();
  }

  render() {
    const {server, iterationId, onSucces, onCancel } = this.props;
    const {parts, loading, error} = this.state;
    const url = "quota/quote?action=convert_to_invoice&id=" + iterationId;
    const data = {
      parts: parts
    }

    const percentagePerPart = (parts > 0 ? Math.floor(100 / parts) : 0 );
    const partsAreEqual = parts > 0 && 100 % parts === 0;
    let sentance = "This will create " + parts;
    sentance += (parts > 1 ? " invoices, " : " invoice, ");
    sentance += (partsAreEqual && parts > 1 ? "each covering " : "covering ");
    if (!partsAreEqual) {
      let percentageLeft = 100;
      for (let i = 0; i< parts; i++) {
        percentageLeft = percentageLeft - percentagePerPart;
        sentance += (i < parts - 1 ? percentagePerPart + "%, " : "and " + (percentagePerPart + percentageLeft) + "%");
      }
    }
    else sentance += percentagePerPart + "%";
    sentance+= " of the total quote value.";

    return(
      <Form
        method="post"
        server={server}
        url={url}
        data={data}
        onSucces={onSucces}
        onCancel={onCancel}>
        <Subtitle label="Convert quote to invoice" />
        <Notification danger>{error}</Notification>
        <Dynamic visible={loading}>
        {this.state.loading &&
          <Loader />
        }
        </Dynamic>
        <Dynamic visible={!loading && !error}>
          <Divider />
          <FormField variant="grouped">
            <FormControl>
            <Label text="Number of invoices" />
              <NumberInput
                name="parts"
                placeholder="1"
                value={parts}
                onChange={this.handleInputChange} />
            </FormControl>
          </FormField>
          <FormField>
            <HelpText className={(!parts ? "is-invisible" : null)}>{sentance}</HelpText>
          </FormField>
        </Dynamic>
        <Divider />
      </Form>
    )

  }
}

export default ConvertToInvoiceForm;
