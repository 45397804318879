import React from 'react';
import Button from './Button';

class LinkToFile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      link: null
    }
    this.requests = [];
    this.download = this.download.bind(this);
  }

  componentWillUnmount() {
    this.props.server.cancelRequests(this.requests);
    this.downloadRequest && this.props.server.abortDownload(this.downloadRequest);
  }

  download(event) {
    const newWindow = window.open("");
    const component = this;
    const {url, server, onError} = component.props;
    component.setState({ loading: true });
    const callback = function(response) {
      component.setState({ loading: false });
      if (response.error) {
        newWindow.close();
        onError(response.data);
      }
      else {
        newWindow.location.href = response.data;
        component.setState({ link: response.data });
      }
    }
    this.requests.push(server.request(callback, 'get', url));
    if (event) event.preventDefault();
  }

  render() {
    return(
      <Button
        tooltip="Link to Invoice"
        disabled={this.props.disabled}
        loading={this.state.loading}
        onClick={this.download}
        icon="link" />
    )
  }
}

export default LinkToFile;
