import React from 'react';
import Subtitle from './Subtitle';
import TextInput from './TextInput';
import Label from './Label';
import Columns from './Columns';
import Column from './Column';
import FormField from './FormField';
import CountrySelector from './CountrySelector';
import Divider from './Divider';
import Form from './Form';
import Dynamic from './Dynamic';
import Loader from './Loader';
import Tabs from './Tabs';
import Tab from './Tab';
import ContactsFields from './ContactsFields';

class CustomerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeTab: "customer",
      name: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      addressLine4: "",
      postalCode: "",
      country: "",
      website: "",
      contactName: "",
      email: "",
      telephone: "",
      mobile: "",
      fax: "",
      additionalContacts: []
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAdditionalContactsChange = this.handleAdditionalContactsChange.bind(this);
    this.switchTabs = this.switchTabs.bind(this);
  }

  componentWillUnmount() {
    this.request && this.props.server.abortRequest(this.request);
  }

  componentDidMount() {
    if (this.props.id) {
      const component = this;
      function callback(response) {
        if (response.error) {
          component.setState({
            error: response.data,
            loading: false,
          });
        }
        else {
          const data = response.data;
          component.setState({
            loading: false,
            name: data.name,
            addressLine1: data.address_line_1,
            addressLine2: data.address_line_2,
            addressLine3: data.address_line_3,
            addressLine4: data.address_line_4,
            postalCode: data.postal_code,
            country: data.country_code,
            website: data.web,
            contactName: data.contact_name,
            email: data.email,
            telephone: data.telephone,
            mobile: data.mobile,
            fax: data.fax,
            additionalContacts: data.additional_contacts
          });
        }
      }
      this.request = this.props.server.request(callback, 'get', 'quota/customer?id=' + component.props.id);
      } else {
        this.setState({
          loading: false
        })
      }
  }

  handleInputChange(event) {
    const name = event.target.attributes['name'].value;
    let value = event.target.value;
    let type = (event.target.attributes['type'] ? event.target.attributes['type'].value : undefined);
    const data = {};
    if (type === "checkbox") value = event.target.checked;
    data[name] = value;
    this.setState(data);
    event.preventDefault();
  }

  handleAdditionalContactsChange(additionalContacts) {
    console.log(additionalContacts);
    this.setState({additionalContacts: additionalContacts});
  }

  switchTabs(tabName) {
    this.setState({
      activeTab: tabName
    });
  }

  render() {
    const {server, id} = this.props;
    const {name, addressLine1, addressLine2, addressLine3, addressLine4, postalCode, country, website, contactName, email, telephone, mobile, fax, additionalContacts} = this.state;
    const action = (id ? "update&id=" + id : "create");
    const url = "quota/customer?action=" + action;
    const data = {
      name: name,
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      address_line_3: addressLine3,
      address_line_4: addressLine4,
      postal_code: postalCode,
      country_code: country,
      web: website,
      contact_name: contactName,
      email: email,
      telephone: telephone,
      mobile: mobile,
      fax: fax,
      additional_contacts: additionalContacts
    }
    return(
      <Form
        method="post"
        server={server}
        url={url}
        data={data}
        onSucces={this.props.onSucces}
        onCancel={this.props.onCancel}>
        <Subtitle label={(this.props.id ? "Edit" : "Create") + " customer"} />
        {this.state.loading &&
          <Loader />
        }
        <Dynamic visible={!this.state.loading}>
        <Divider />
        <Tabs>
          <Tab
            name="customer"
            active={this.state.activeTab === "customer"}
            onClick={this.switchTabs}
            label="Customer details"
            icon="briefcase" />
          <Tab
            name="contacts"
            active={this.state.activeTab === "contacts"}
            onClick={this.switchTabs}
            label="Contacts"
            icon="user-tie" />
        </Tabs>
        <Dynamic visible={this.state.activeTab === "customer"}>
          <Columns>
            <Column>
              <FormField>
                <TextInput
                  name="name"
                  placeholder="Customer name"
                  value={this.state.name}
                  onChange={this.handleInputChange}
                />
              </FormField>
              <FormField>
                <TextInput
                  name="addressLine1"
                  placeholder="Address line 1"
                  value={this.state.addressLine1}
                  onChange={this.handleInputChange}
                />
              </FormField>
              <FormField>
                <TextInput
                  name="addressLine2"
                  placeholder="Address line 2"
                  value={this.state.addressLine2}
                  onChange={this.handleInputChange}
                />
              </FormField>
              <FormField>
                <TextInput
                  name="addressLine3"
                  placeholder="Address line 3"
                  value={this.state.addressLine3}
                  onChange={this.handleInputChange}
                />
              </FormField>
              <FormField>
                <TextInput
                  name="addressLine4"
                  placeholder="Address line 4"
                  value={this.state.addressLine4}
                  onChange={this.handleInputChange}
                />
              </FormField>
              <FormField>
                <TextInput
                  name="postalCode"
                  placeholder="Postal code"
                  value={this.state.postalCode}
                  onChange={this.handleInputChange}
                />
              </FormField>
              <FormField>
                <CountrySelector
                  value={this.state.country}
                  onChange={this.handleInputChange} />
              </FormField>
            </Column>
            <Column>
              <FormField>
                <TextInput
                  name="website"
                  placeholder="Website"
                  value={this.state.website}
                  onChange={this.handleInputChange}
                />
              </FormField>
              <FormField>
                <TextInput
                  name="telephone"
                  placeholder="Telephone"
                  value={this.state.telephone}
                  onChange={this.handleInputChange}
                />
              </FormField>
              <FormField>
                <TextInput
                  name="mobile"
                  placeholder="Mobile"
                  value={this.state.mobile}
                  onChange={this.handleInputChange}
                />
              </FormField>
              <FormField>
                <TextInput
                  name="fax"
                  placeholder="Fax"
                  value={this.state.fax}
                  onChange={this.handleInputChange}
                />
              </FormField>
            </Column>
          </Columns>
        </Dynamic>
        <Dynamic visible={this.state.activeTab === "contacts"}>
          <Label text="Primary contact" />
          <FormField variant="grouped">
            <TextInput
              name="contactName"
              placeholder="Name"
              value={this.state.contactName}
              onChange={this.handleInputChange}
            />
            <TextInput
              expanded
              name="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleInputChange}
            />
          </FormField>
          <Label text="Additional Contacts" />
          <ContactsFields
            contacts={this.state.additionalContacts}
            onChange={this.handleAdditionalContactsChange} />
        </Dynamic>
      </Dynamic>
      <Divider />
      </Form>
    )
  }
}

export default CustomerForm;
