import React from 'react';
import Select from './Select';
import FormField from './FormField';
import Label from './Label';

function CurrencySelector({value, name, onChange}) {
  let options = [
    {
      key: 'GBP',
      value: 'GBP',
      label: '£',
    },
    {
      key: 'USD',
      value: 'USD',
      label: '$',
    }
  ];
  return (
    <FormField>
      <Label text="Currency" />
      <Select
        name="currency"
        value={value}
        placeholder="Select currency"
        options={options}
        onChange={onChange}/>
    </FormField>
  );
}

export default CurrencySelector;
