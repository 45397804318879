import React from 'react';
import Subtitle from './Subtitle';
import Divider from './Divider';
import Table from './Table';
import TableCell from './TableCell';
import TableRow from './TableRow';
import FormField from './FormField';
import CreateButton from './CreateButton';
import PaymentForm from './PaymentForm';
import Payment from './Payment';
import Notification from './Notification';
import Dynamic from './Dynamic';
import Loader from './Loader';

class PaymentManager extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      payments: props.invoice.payments,
      addingPayment: false,
      editingPayment: false
    }
    this.addPayment = this.addPayment.bind(this);
    this.deletePayment = this.deletePayment.bind(this);
    this.hidePaymentForm = this.hidePaymentForm.bind(this);
  }

  componentWillUnmount() {
    this.request && this.props.server.abortRequest(this.request);
  }

  deletePayment(event, id) {
    if (window.confirm('Are you sure you want to delete this payment?')) {
      const component = this;
      component.setState({ loading: true, error: false });
      const callback = function(response) {
        component.setState({ loading: false });
        if (response.error) component.setState({ error: response.data })
        else component.props.onChange(response.data);
      }
      this.request = this.props.server.request(callback, 'delete', 'tracka/payment?id=' + id);
    }
    event.preventDefault();
  }

  addPayment(event) {
    this.setState({ addingPayment: true });
    event.preventDefault();
  }

  editPayment(event, key) {
    console.log(key);
    this.setState({
      editingPayment: key
    })
    event.preventDefault();
  }

  hidePaymentForm(event) {
    this.setState({
      addingPayment: false,
      editingPayment: false
    })
  }

  render() {
    const invoice = this.props.invoice;
    if (this.state.addingPayment !== false || this.state.editingPayment !== false) {
      const editing = this.state.editingPayment !== false;
      let payment = null;
      if (editing) payment = this.state.payments[this.state.editingPayment];
      return (
        <PaymentForm
          id={(editing ? payment.id : false)}
          server={this.props.server}
          onSucces={this.props.onChange}
          onCancel={this.hidePaymentForm}
          defaultAmount={(editing ? payment.amount : invoice.payment_due)}
          defaultDate={(editing ? payment.date : false)}
          invoiceId={invoice.id}
          invoiceNumber={invoice.number} />
      );
    }
    else {
      const {error, loading} = this.state;
      const payments = this.props.invoice.payments.map((payment, index) =>
      <Payment
        readonly={this.props.readonly}
        onDelete={(e) => this.deletePayment(e, payment.id)}
        onEdit={(e) => this.editPayment(e, index)}
        key={payment.id}
        payment={payment} />
      );
      console.log(this.props.readonly);
      return(
        <div>
          <Subtitle className="less-padding" label={"Payments for Invoice #" + invoice.number} />
          <Notification danger>{error}</Notification>
          <Dynamic visible={loading}>
            <Loader />
          </Dynamic>
          <Dynamic visible={!loading && !error}>
            <Divider />
            {invoice.payments.length > 0 &&
            <Table fullwidth>
              <thead>
                <TableRow>
                  <TableCell heading>
                    Date
                  </TableCell>
                  <TableCell heading>
                    Amount
                  </TableCell>
                  {!this.props.readonly &&
                    <TableCell heading className="has-text-right">
                      Actions
                    </TableCell>
                  }
                </TableRow>
              </thead>
              {payments}
            </Table>
          }
          {invoice.payments.length < 1 &&
            <FormField>
              <p>No payments were made yet.</p>
            </FormField>
          }
          {!this.props.readonly &&
            <FormField>
              <CreateButton
                onClick={this.addPayment}
                label="Add payment" />
            </FormField>
          }
          </Dynamic>
        </div>
      )
    }
  }
}

export default PaymentManager;
