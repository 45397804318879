import React from 'react';
import ReactDOM from 'react-dom';
import ModalContent from './ModalContent';

const modalRoot = document.getElementById('modal-root');

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { closed: false }
    this.close = this.close.bind(this);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
    this.setState({closed: false})
  }
  close(event) {
    this.setState({closed: true})
    event.preventDefault();
  }
  render() {
    if (this.state.closed) {
      return null;
    }
    else {
      return ReactDOM.createPortal(
        this.renderModal(),
        this.el
      );
      
    }
  }

  renderModal() {
    return (
      <div className="modal is-active has-text-left is-wide">
        <div className="modal-background" onClick={this.props.onClose}></div>
        <div className="modal-content">
          <ModalContent onClose={this.close} notification={this.props.notification}>
            {this.props.children}
          </ModalContent>
        </div>
      </div>
    )
  }
}

export default Modal;
