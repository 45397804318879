import React from 'react';

function Icon(props) {
  let color = 'grey-light';
  if (props.color) color = props.color
  return(
    <span className="icon">
      <i className={"has-text-" + color + " fas fa-" + props.name + " " + props.className}  aria-hidden="true" />
    </span>
  )
}

export default Icon;
