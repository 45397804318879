import React from 'react';
import Button from './Button';

function CreateButton({url, label, className, onClick}) {
    return (
      <Button
        className={className}
        icon="plus" url={url}
        label={(label === undefined ? 'Create': label)}
        onClick={onClick} />
    );
}

export default CreateButton;
