import React from 'react';
import Subtitle from './Subtitle';
import Divider from './Divider';
import Form from './Form';
import FormField from './FormField';
import Label from './Label';
import TextInput from './TextInput';
import NumberInput from './NumberInput';

class PaymentForm extends React.Component {

  constructor(props) {
    super(props);
    let defaultDate = props.defaultDate;
    if (!defaultDate) {
      const today = new Date();
      const dd = today.getDate();
      const mm = today.getMonth()+1;
      const yyyy = today.getFullYear();
      defaultDate = yyyy + '-' + mm + '-' + dd;
    }

    this.state = {
      date: defaultDate,
      amount: props.defaultAmount
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name] : value
    });
    event.preventDefault();
  }

  render() {
    const data = {
      date: this.state.date,
      amount: this.state.amount,
      invoice_id: this.props.invoiceId
    }
    const action = (this.props.id ? "update" : "create");
    const url = "tracka/payment?action=" + action + (this.props.id ? "&id=" + this.props.id : "");
    return(
      <Form
        method="post"
        data={data}
        url={url}
        server={this.props.server}
        onSucces={this.props.onSucces}
        onCancel={this.props.onCancel}>
        <Subtitle label={"Add payment for Invoice #" + this.props.invoiceNumber} />
        <Divider />
        <FormField>
          <Label text="Date" />
          <TextInput
            name="date"
            placeholder="YYYY-MM-DD"
            value={this.state.date}
            onChange={this.handleInputChange} />
        </FormField>
        <FormField>
          <Label text="Amount" />
          <NumberInput
          onChange={this.handleInputChange}
            name="amount"
            value={this.state.amount} />
        </FormField>
        <Divider />
      </Form>
    )
  }
}

export default PaymentForm;
