import React from 'react';
import Button from './Button.js';

function SendRemindersButton(props) {
  return(
    <Button
      tooltip="Send Reminders"
      onClick={props.onClick}
      icon="bell" />
  )
}

export default SendRemindersButton;
