import React from 'react';
import Button from './Button';

class ApproveButton extends React.Component {
  constructor(props) {
    super(props);
    this.requests = [];
    this.state = { loading: false }
    this.handleApprove = this.handleApprove.bind(this);
  }

  componentWillUnmount() {
    this.props.server.cancelRequests(this.requests);
  }

  handleApprove(event) {
    const component = this;
    component.setState({loading: true});
    component.props.onClick(event);
    const callback = function(response) {
      component.setState({loading: false});
      if (response.error) component.props.onError(response.data);
      else component.props.onSucces(response.data);
    }
    this.requests.push(this.props.server.request(callback, "post", "quota/quote?action=approve&id=" + this.props.quoteId + "&iteration_id=" + this.props.id));
    event.preventDefault();
  }

  render() {
    return(
      <Button
        tooltip="Approve"
        disabled={this.props.disabled}
        loading={this.state.loading}
        icon="check"
        onClick={this.handleApprove} />
    )
  }
}

export default ApproveButton;
