import React from 'react';
import Icon from './Icon';
class Tab extends React.Component {
  constructor(props) {
    super(props);
    this.handleTabSwitch = this.handleTabSwitch.bind(this);
  }

  handleTabSwitch(event) {
    this.props.onClick(this.props.name);
    event.preventDefault();
  }

  render() {
    return (
      <li onClick={this.handleTabSwitch} className={(this.props.active ? "is-active" : "") + " " + this.props.className}>
        <a>
          {this.props.icon &&
            <Icon color="blue" name={this.props.icon} />
          }
          <span>{this.props.label}</span>
        </a>
      </li>
    )
  }
}

export default Tab;
