import React from 'react';
import Select from './Select';
import FormField from './FormField';

function CountrySelector({value, name, onChange}) {
  let options = [
    {
      key: 'GB',
      value: 'GB',
      label: 'United Kingdom',
    },
    {
      key: 'US',
      value: 'US',
      label: 'United states',
    }
  ];
  return (
    <FormField>
      <Select
        name="country"
        value={value}
        placeholder="Select country"
        options={options}
        onChange={onChange}/>
    </FormField>
  );
}

export default CountrySelector;
