import React from 'react';
import Select from './Select.js';
import FormField from './FormField.js';
import FormControl from './FormControl.js';
import Label from './Label.js';
import CreateButton from './CreateButton.js';
import EditButton from './EditButton.js';
import Modal from './Modal';
import CustomerForm from './CustomerForm';
import SyncCustomersButton from './SyncCustomersButton';

class CustomerSelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      customers: [],
      editingCustomer: false,
      creatingCustomer: false,
    };
    this.toggleCustomerEditForm = this.toggleCustomerEditForm.bind(this);
    this.toggleCustomerCreateForm = this.toggleCustomerCreateForm.bind(this);
    this.hideCustomerForm = this.hideCustomerForm.bind(this);
    this.loadCustomers = this.loadCustomers.bind(this);
  }

  componentWillUnmount() {
    this.request && this.props.server.abortRequest(this.request);
  }

  componentDidMount() {
    this.loadCustomers();
  }

  loadCustomers() {
    const component = this;
    component.setState({ loading: true });
    const callback = function(response) {
      if (!response.error) {
        component.setState({
          loading: false,
          customers: response.data
        });
      }
      component.props.customersLoaded(response);
    }
    this.request = component.props.server.request(callback, 'get', 'quota/customer');
  }

  toggleCustomerCreateForm(event) {
    this.setState({
      creatingCustomer: true
    });
    if (event) event.preventDefault();
  }

  toggleCustomerEditForm(event) {
    this.setState({
      editingCustomer: true
    });
    if (event) event.preventDefault();
  }

  hideCustomerForm() {
    this.setState({
      editingCustomer: false,
      creatingCustomer: false
    });
    this.loadCustomers();
  }

  render() {
    const selectedCustomer = this.props.selected;
    const selectedContact = this.props.contact;
    const customers = this.state.customers;
    const customerOptions = [];
    const contactOptions = [];
    if (this.props.allCustomersSelectable) {
      customerOptions.push({
        label: "All Customers",
        value: "all",
        key: 0,
      });
    }
    for (let i=0; i < customers.length; i++) {
      const customer = customers[i];
      customerOptions.push({
        label: customer.name,
        value: customer.id,
        key: customer.id,
      });
      if (String(customer.id) === String(selectedCustomer)) {
        contactOptions.push({
          label: customer.contact.name,
          value: customer.contact.name,
          key: 0
        })
        for (let x=0; x < customer.additional_contacts.length; x++) {
          const contact = customer.additional_contacts[x];
          if (contact.name.length > 0) {
            contactOptions.push({
              label: contact.name,
              value: contact.name,
              key: x + 1
            })
          }
        }
      }
    }
    return (
      <div>
      {(this.state.editingCustomer || this.state.creatingCustomer) &&
        <Modal onClose={this.hideCustomerForm}>
          <CustomerForm
            id={(this.state.editingCustomer ? selectedCustomer : false)}
            server={this.props.server}
            onSucces={this.hideCustomerForm}
            onCancel={this.hideCustomerForm}/>
        </Modal>
      }
      <FormField variant="grouped">
        <Select
          disabled={this.props.disabled}
          variant={(this.state.loading ? "loading" : null)}
          placeholder={(this.props.allCustomersSelectable ? false : "Select customer")}
          name="customer"
          value={selectedCustomer}
          onChange={this.props.onChange}
          options={customerOptions} />
          <FormControl>
        {this.props.syncButton &&
          <SyncCustomersButton
            server={this.props.server}
            disabled={this.state.loading} />
        }
          </FormControl>
      </FormField>
      {this.props.contactSelector &&
        <FormField>
          <Label text="Customer Contact" />
          <Select
            disabled={!this.props.selected}
            placeholder="Select contact"
            name="contact"
            value={selectedContact}
            onChange={this.props.onChange}
            options={contactOptions} />
          </FormField>
      }
      {this.props.customerEditor &&
        <FormField variant="grouped">
          <CreateButton
            onClick={this.toggleCustomerCreateForm}
            label="New customer"
            className="has-margin-right" />
          {this.props.selected !== null &&
            <EditButton
              onClick={this.toggleCustomerEditForm}
              label="Edit this customer" />
          }
        </FormField>
      }
      </div>
    )
  }
}

export default CustomerSelector;
