import React from 'react';

function QuoteActions(props) {
  return (
    <ul className={props.className}>
      {props.children}
    </ul>
  );
}

export default QuoteActions;
