import React from 'react';
import { Link } from 'react-router-dom';

function QuoteCustomer(props) {
  if (props.link) {
    return (
      <Link to={"/quota/filter/" + props.id + "/" + props.name}>{props.name}</Link>
    );
  }
  else  return props.name;
}

export default QuoteCustomer;
