import React from 'react';

function Columns(props) {
  let className = "columns";
  if (props.gap) className += " is-" + props.gap;
  return(
    <div className={className}>
      {props.children}
    </div>
  )
}

export default Columns;
