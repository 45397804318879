import React from 'react';
import MailInvoiceForm from './MailInvoiceForm';
import Modal from './Modal';
import Button from './Button';

class PublishInvoiceButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      publishing: false,
      number: null
    }
    this.requests = [];
    this.handleSucces = this.handleSucces.bind(this);
    this.showPublishForm = this.showPublishForm.bind(this);
    this.hidePublishForm = this.hidePublishForm.bind(this);
  }

  componentWillUnmount() {
    this.props.server.cancelRequests(this.requests);
  }

  hidePublishForm(event) {
    this.setState({
      publishing: false,
      loading: false
    });
    this.props.onCancel(event);
    event.preventDefault();
  }

  showPublishForm(event) {
    this.setState({
      publishing: true,
      loading: true
    });
    const component = this;
    const callback = function(response) {
      if (!response.error) component.setState({ number: response.data });
      component.setState({ loading: false });
    }
    this.requests.push(this.props.server.request(callback, 'get', 'quota/invoice?action=nextNumber'));
    this.props.onClick(event);
    event.preventDefault();
  }

  handleSucces(data) {
    this.setState({
      publishing: false
    })
    this.props.onSucces(data);
  }

  render() {
    const { invoice, index, total, server } = this.props;
    return(
      <span>
        <Button
          tooltip="Publish"
          disabled={invoice.marked_as_sent}
          loading={this.state.publishing || this.state.loading}
          onClick={(event) => this.showPublishForm(event, invoice.id)}
          icon="envelope" />
        {this.state.publishing && !this.state.loading &&
        <Modal onClose={this.hidePublishForm}>
          <MailInvoiceForm
            id={invoice.id}
            index={index}
            number={this.state.number}
            title={invoice.project_title}
            total={total}
            percentage={invoice.percentage}
            iterationId={invoice.iteration_id}
            server={server}
            onCancel={this.hidePublishForm}
            onSucces={this.handleSucces} />
        </Modal>
        }
    </span>
    );
  }
}
export default PublishInvoiceButton;
