import React from 'react';
import Modal from './Modal';
import Notification from './Notification';
import TextInput from './TextInput';
import PasswordInput from './PasswordInput';
import Label from './Label';
import FormField from './FormField';
import SubmitButton from './SubmitButton';
import Logo from './Logo';
import Divider from './Divider';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loading: false,
      username: "",
      password: "",
    }
    this.authenticate = this.authenticate.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillUnmount() {
    this.request && this.props.server.abortRequest(this.request);
  }

  handleInputChange(event) {
    const name = event.target.attributes['name'].value;
    const value = event.target.value;
    const data = {};
    data[name] = value;
    this.setState(data);
    event.preventDefault();
  }

  authenticate(event) {
    const component = this;
    component.setState({loading: true, error: false})
    const callback = function(response) {
      if (response.error) component.setState({loading: false, error: response.data});
      else component.props.onSuccess(response.data.name, response.data.token);
    }
    this.request = this.props.server.request(callback, 'get', 'authenticate', {}, component.state.username, component.state.password);
    event.preventDefault();
  }

  render() {
    return(
      <Modal>
        <FormField className="has-text-centered">
          <Logo />
        </FormField>
        <Divider />
        <Notification danger>{this.state.error}</Notification>
        <FormField>
          <Label text="Username" />
          <TextInput
            name="username"
            value={this.state.username}
            placeholder="Username"
            onChange={this.handleInputChange} />
        </FormField>
        <FormField>
          <Label text="Password" />
          <PasswordInput
            name="password"
            value={this.state.password}
            placeholder="Password"
            onChange={this.handleInputChange} />
        </FormField>
        <FormField>
          <SubmitButton loading={this.state.loading}  onClick={this.authenticate} />
        </FormField>
      </Modal>
    )
  }
}

export default Login;
