import React from 'react';
import Button from './Button.js';
import FormControl from './FormControl.js';

function FormField({variant, addon, prependAddon, children, className, disabled}) {
  let completeClassName = "field ";
  if (variant) completeClassName += ' is-' + variant;
  if (addon) completeClassName += ' has-addons';
  if (disabled) completeClassName += ' is-disabled';
  if (className) completeClassName += " " + className
  return(
    <div className={completeClassName}>
      {addon && prependAddon &&
        <FormControl>
          <Button variant="static" label={addon} />
        </FormControl>
      }
      {children}
      {addon && !prependAddon &&
        <FormControl>
          <Button variant="static" label={addon} />
        </FormControl>
      }
    </div>
  )
}

export default FormField;
