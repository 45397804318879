import React from 'react';
import Modal from './Modal';
import Button from './Button';

class SyncCustomersButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      response: false
    }
    this.requests = [];
    this.sync = this.sync.bind(this);
  }

  componentWillUnmount() {
    this.props.server.cancelRequests(this.requests);
  }

  sync(event) {
    const component = this;
    const {server} = this.props;
    component.setState({
      loading: true,
      error: false,
      response: false
    });
    const callback = function(response) {
      component.setState({ loading: false });
      if (response.error) {
        component.setState({error: response.data});
      }
      else {
        component.setState({ response: response.data });
      }
    }
    this.requests.push(server.request(callback, 'get', 'customer/syncWithKashFlow' ));
    if (event) event.preventDefault();
  }

  render() {
    const {disabled} = this.props;
    const {loading, error, response} = this.state;
    return(
      <span>
      {(error || response) &&
        <Modal notification>
            {error &&
              <p>{error}</p>
            }
            {response &&
              <p>{response}</p>
            }
        </Modal>
      }
      <Button
        tooltip="Sync customers with KashFlow"
        disabled={disabled}
        loading={loading}
        onClick={this.sync}
        icon="sync" />
      </span>
    )
  }
}

export default SyncCustomersButton;
