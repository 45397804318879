import React from 'react';
import FormControl from './FormControl.js';

function TextInput({expanded, placeholder, disabled, value, name, onChange}) {
  return (
    <FormControl variant={(expanded ? "expanded" : null)}>
      <input
        className={"input "}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        type="password"
        name={name}
        onChange={onChange} />
    </FormControl>
  )
}

export default TextInput;
