import React from 'react';
import QuoteForm from './QuoteForm';

class EditQuote extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      iteration: this.props.iteration
    }
    this.switchIteration = this.switchIteration.bind(this);
  }

  switchIteration(id) {
    var url = window.location.href.split("/");
    var iterationIdFromUrl = url[url.length - 1];
    window.location.href = window.location.href.replace(iterationIdFromUrl, id);
    this.setState({ iteration: id })
  }

  render() {
    return(
      <div>
        <QuoteForm
          id={this.state.id}
          switchIteration={this.switchIteration}
          iteration={this.state.iteration}
          server={this.props.server} />
      </div>

    )
  }
}

export default EditQuote;
