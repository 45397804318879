import React from 'react';
import DynamicFields from './DynamicFields';
import ContactsField from './ContactsField';

class ContactsFields extends React.Component {
  constructor(props) {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleCreateField = this.handleCreateField.bind(this);
    this.handleDeleteField = this.handleDeleteField.bind(this);
  }

  handleFieldChange(event, key, name) {
    const contacts = this.props.contacts;
    contacts[key][name] = event.target.value;
    this.props.onChange(contacts);
    event.preventDefault();
  }

  handleDeleteField(key, event = false) {
    const contacts = this.props.contacts;
    contacts.splice(key, 1);
    this.props.onChange(contacts);
    if (event) event.preventDefault();
  }

  handleCreateField(key) {
    const contacts = this.props.contacts;
    contacts.push({
      name: "",
      email: ""
    });
    this.props.onChange(contacts);
  }

  render() {
    const component = this;
    const newKey = component.props.contacts.length;
    const contact = <ContactsField key={newKey} name="" email="" onChange={component.handleFieldChange} />;
    const contacts = this.props.contacts.map((contact, index) =>
      <ContactsField
        key={index}
        name={contact.name}
        email={contact.email}
        onCreateField={component.handleCreateField}
        onDeleteField={(event) => component.handleDeleteField(index, event)}
        onChange={(event, name) => component.handleFieldChange(event, index, name)} />
    );
    return(
      <DynamicFields
        fieldType="custom"
        fields={contacts}
        field={contact}
        columns="2"
        fieldSingleName="contact"
        onCreateField={this.handleCreateField}
        onDeleteField={this.handleDeleteField} />
    );
  }
}

export default ContactsFields;
