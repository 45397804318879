import React from 'react';
import { Redirect } from 'react-router-dom';
import Table from '../components/Table';
import TableRow from '../components/TableRow';
import TableCell from '../components/TableCell';
import Loader from '../components/Loader';
import Notification from '../components/Notification';
import FormField from '../components/FormField';
import Divider from '../components/Divider';
import Dynamic from '../components/Dynamic';
import TrackaInvoice from '../components/TrackaInvoice';
import ItemsPerPageSelector from '../components/ItemsPerPageSelector';
import Pagination from '../components/Pagination';
import CustomerSelector from '../components/CustomerSelector';
import TrackaPublicLinkButton from '../components/TrackaPublicLinkButton';
import SendReminders from '../components/SendReminders';
import SyncWithKashFlowButton from '../components/SyncWithKashFlowButton';

class TrackaInvoices extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      firstTimeLoading: true,
      invoices: [],
      selectedInvoices: [],
      selectedInvoicesCustomer: null,
      loading: true,
      invoicesLoaded: false,
      error: null,
      succes: false,
      itemsPerPage: 50,
      pageNumber: 0,
      totalPages: 0,
      customerChangedTo: false
    }

    this.handleError = this.handleError.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleItemsPerPageChange = this.handleItemsPerPageChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSelectInvoice = this.handleSelectInvoice.bind(this);

  }

  componentDidMount() {
    this.loadInvoices();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.customer !== this.props.customer) {
      this.setState({ customerChangedTo: false });
      this.loadInvoices();
    }
  }

  handleError(message) {
    this.setState({
      error: message,
      succes: false
    });
  }

  loadInvoices(pageNumber = 0, itemsPerPage = this.state.itemsPerPage) {
    const component = this;
    component.setState({
      invoices: [],
      loading: true,
      invoicesLoaded: false,
      selectedInvoices: [],
      selectedInvoicesCustomer: false
    });
    const callback = function(response) {
      if (response.error) {
        component.setState({
          succes: false,
          error: response.data,
          firstTimeLoading: false,
          firstTimeLoaded: false,
          invoicesLoaded: false,
          loading: false
        });
      }
      else {
        component.setState({
          invoices: response.data,
          error: null,
          invoicesLoaded: true,
          firstTimeLoading: false,
          firstTimeLoaded: true,
          loading: false,
          totalPages: Math.ceil(response.attributes.count / itemsPerPage) - 1,
          pageNumber: pageNumber
        })
      }
    }
    if (this.props.readonly) {
      this.request = this.props.server.request(callback, 'get', 'tracka/public/invoice?customer_id=' + this.props.customer + '&username=' + this.props.username + '&password=' + this.props.password + '&offset=' + pageNumber * itemsPerPage + '&limit=' + itemsPerPage);
    }
    else {
      this.request = this.props.server.request(callback, 'get', 'tracka/invoice' + (this.props.customer ? '?customer=' + this.props.customer + '&' : '?') + 'offset=' + pageNumber * itemsPerPage + '&limit=' + itemsPerPage);
    }
  }

  componentWillUnmount() {
    this.request && this.props.server.abortRequest(this.request);
  }

  handleItemsPerPageChange(event) {
    const target = event.target;
    const value = target.value;
    this.setState({
      itemsPerPage: value,
      pageNumber: 0
    });
    this.loadInvoices(0, value);
    event.preventDefault();
  }

  handlePageChange(targetPage) {
    this.loadInvoices(targetPage);
  }

  onCustomersLoaded() {
    return false;
  }

  handleCustomerChange(event) {
    const target = event.target;
    const value = target.value;
    this.setState({customerChangedTo: value });
    event.preventDefault();
  }

  handleChange(){
    this.loadInvoices();
  }

  handleSelectInvoice(event, selected, invoice) {
    if (this.state.selectedInvoicesCustomer && this.state.selectedInvoicesCustomer !== invoice.customer.id) {
      return false;
    }
    this.setState({
      selectedInvoicesCustomer: invoice.customer.id
    })
    if (selected) {
      this.setState({
        selectedInvoices: [invoice, ...this.state.selectedInvoices]
      })
    }
    else {
      const selectedInvoices = this.state.selectedInvoices.filter(item => item.id !== invoice.id);
      if (selectedInvoices.length < 1) {
        this.setState({
          selectedInvoicesCustomer: false
        })
      }
      this.setState({
        selectedInvoices: selectedInvoices
      })
    }
    event.preventDefault();
  }

  render() {
    const credentials = {
      id: this.props.customer,
      username: this.props.username,
      password: this.props.password
    }
    const selected = [];
    for (let i=0; i < this.state.selectedInvoices.length; i++) {

      selected.push(this.state.selectedInvoices[i].id);
    }
    console.log(selected);
    const invoices = this.state.invoices.map((invoice) =>
    <TrackaInvoice
      readonly={this.props.readonly}
      credentials={(this.props.readonly ? credentials : null)}
      key={invoice.number}
      server={this.props.server}
      onChange={this.handleChange}
      onSelect={(e, checked) => this.handleSelectInvoice(e, checked, invoice)}
      selected={selected.indexOf(invoice.id) > -1}
      hideCheckbox={this.state.selectedInvoicesCustomer && invoice.customer.id !== this.state.selectedInvoicesCustomer}
      onError={this.handleError}
      handleLoading={this.handleLoading}
      data={invoice}
      customerLink={!this.props.customer} />
    );
    if (this.state.firstTimeLoading) {
      return <Loader />;
    }
    else if (!this.state.invoicesLoaded && !this.state.firstTimeLoaded) {
      return <Notification danger>{this.state.error}</Notification>;
    }
    else if (this.state.customerChangedTo) {
      if (this.state.customerChangedTo === "all") {
        return <Redirect to="/tracka" />
      }
      else {
        return <Redirect to={"/tracka/filter/" + this.state.customerChangedTo} />
      }
    }
    else {
      return(
        <div className="is-relative">
          {this.state.loading && <Loader />}
          <Dynamic visible={!this.state.loading}>
            <Notification danger>{this.state.error}</Notification>
            {!this.props.readonly &&
              <div>
              <FormField variant="grouped" className="absolutely-right">
                {this.state.selectedInvoices.length > 0 &&
                  <SendReminders
                    onError={this.handleError}
                    customer={this.state.selectedInvoicesCustomer}
                    invoices={this.state.selectedInvoices}
                    onSucces={this.handleChange}
                    server={this.props.server}
                  />
                }
                <TrackaPublicLinkButton
                  customerId={(this.props.customer ? this.props.customer : "*")}
                  server={this.props.server}
                  onError={this.handleError} />
                <SyncWithKashFlowButton server={this.props.server} />
              </FormField>
                <FormField variant="grouped">
                  <CustomerSelector
                    selected={this.props.customer}
                    server={this.props.server}
                    onError={this.handleError}
                    onChange={this.handleCustomerChange}
                    customersLoaded={this.onCustomersLoaded}
                    allCustomersSelectable
                    contactSelector={false}
                    customerEditor={false}
                    />
                </FormField>
              </div>
            }
            <Divider />
            <Table fullwidth hoverable>
              <thead>
                <TableRow>
                  <TableCell heading narrow>
                    #
                  </TableCell>
                  <TableCell heading wide>
                    Project Name
                  </TableCell>
                  <TableCell heading>
                  </TableCell>
                  <TableCell heading>
                    Invoice Date
                  </TableCell>
                  <TableCell heading>
                    Due Date
                  </TableCell>
                  <TableCell heading>
                    Invoice Amount
                  </TableCell>
                  <TableCell heading>
                    Payment Received
                  </TableCell>
                  <TableCell heading>
                    Amount Due
                  </TableCell>
                  <TableCell heading>
                    Payment Status
                  </TableCell>
                  {!this.props.readonly &&
                  <TableCell heading narrow>
                  </TableCell>
                  }
                </TableRow>
              </thead>
              {invoices}
            </Table>
            <Divider />
            <ItemsPerPageSelector
              value={this.state.itemsPerPage}
              onChange={this.handleItemsPerPageChange} />
            <Pagination
              totalPages={this.state.totalPages}
              currentPageNumber={this.state.pageNumber}
              onPageChange={this.handlePageChange} />
          </Dynamic>
        </div>
      )
    }
  }
}

export default TrackaInvoices;
