import React from 'react';
import TableCell from './TableCell';
import TableRow from './TableRow';
import Tag from '../components/Tag';
import Checkbox from '../components/Checkbox';
import Modal from '../components/Modal';
import PaymentStatus from '../components/PaymentStatus';
import PaymentManager from '../components/PaymentManager';
import LinkToFile from '../components/LinkToFile';
import Button from '../components/Button';
import FormField from '../components/FormField';
import numberWithCommas from './../functions/numberWithCommas.js';

class TrackaInvoice extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      managingPayment: false
    }
    this.requests = [];
    this.showPaymentManager = this.showPaymentManager.bind(this);
    this.hidePaymentManager = this.hidePaymentManager.bind(this);
  }

  componentWillUnmount() {
    if (this.requests) for (let i=0; i < this.request; i++) this.props.server.abortRequest(this.requests[i]);
  }

  showPaymentManager(event) {
    this.setState({ managingPayment: true });
    event.preventDefault();
  }

  hidePaymentManager(event = null) {
    this.setState({ managingPayment: false });
    if (event) event.preventDefault();
  }

  render() {
      const invoice = this.props.data;
      const credentials = this.props.credentials;
      let linkToFileParameters = "?id=" + invoice.id;
      if (this.props.readonly) {
        linkToFileParameters = linkToFileParameters + "&customer_id=" + credentials.id + "&username=" + credentials.username + "&password=" + credentials.password;
      }
      return(
        <tbody>
          <TableRow>
            <TableCell>{invoice.number}</TableCell>
            <TableCell heading wide>{invoice.project_title}</TableCell>
            <TableCell>
              <LinkToFile
                url={"tracka" + (this.props.readonly ? "/public/" : "/") + "invoice/link" + linkToFileParameters}
                server={this.props.server}
                onError={this.props.onError}
                id={invoice.id} />
            </TableCell>
            <TableCell><Tag label={invoice.date} /></TableCell>
            <TableCell><Tag label={invoice.due_date} /></TableCell>
            <TableCell>{numberWithCommas(invoice.total)}</TableCell>
            <TableCell>{numberWithCommas(invoice.payment_received)}</TableCell>
            <TableCell>{numberWithCommas(invoice.payment_due)}</TableCell>
            <TableCell>
              <FormField variant="grouped">
                <PaymentStatus
                  readonly={this.props.readonly}
                  onClick={this.showPaymentManager}
                  status={invoice.payment_status} />
                {this.state.managingPayment &&
                  <Modal onClose={this.hidePaymentManager}>
                    <PaymentManager
                      readonly={this.props.readonly}
                      onChange={this.props.onChange}
                      server={this.props.server}
                      invoice={invoice}
                    />
                  </Modal>
                }
                {invoice.payment_status !== "paid" && invoice.reminders.length > 0 &&
                  <Button
                    className="read-only"
                    tooltip={(invoice.reminders.length > 1 ? "Last r" : "R") + "eminder sent on " + invoice.reminders[invoice.reminders.length - 1].date}
                    label={(invoice.reminders.length > 1 ? invoice.reminders.length : null)}
                    variant="danger"
                    color="white"
                    icon="bell" />
                }
              </FormField>
            </TableCell>
            {!this.props.readonly &&
              <TableCell className="has-text-right">
                <Checkbox
                  disabled={this.props.hideCheckbox}
                  onChange={this.props.onSelect}
                  checked={this.props.selected} />
              </TableCell>
            }
          </TableRow>
        </tbody>
      )
  }
}

export default TrackaInvoice;
