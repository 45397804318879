import React from 'react';
import Table from './Table';
import TableCell from './TableCell';
import TableRow from './TableRow';
import NumberInput from './NumberInput';
import TextInput from './TextInput';
import FormField from './FormField';
import numberWithCommas from './../functions/numberWithCommas.js'

class CostSummary extends React.Component {

  render() {
    const {modules, onChange, discount, discountPercentage, renderingCost, productionManagementCost, productionManagementPercentage, extraCosts, extraCostsDescription, vatRate} = this.props;
    let net = Number(productionManagementCost) + Number(renderingCost) + Number(extraCosts) - Number(discount);
    for(let i=0; i < modules.length; i++) net += Number(modules[i].total);
    const vat = (Number(vatRate) / 100) * net;
    const total =  net + vat;
    const rows = modules.map((module, index) =>
      <TableRow key={module.name}>
        <TableCell heading narrow>
          {module.name.toUpperCase()}
        </TableCell>
        <TableCell heading wide>
          {module.description}
        </TableCell>
        <TableCell heading wide>
          {module.details}
        </TableCell>
        <TableCell narrow>
          <NumberInput
            disabled
            value={(module.rate ? numberWithCommas(module.rate) : "0.00")}
            onChange={(e) => e.preventDefault()} />
        </TableCell>
        <TableCell narrow>
          <NumberInput
            name="quantity"
            value={module.quantity}
            placeholder="Qty"
            onChange={(event) => this.props.onModuleQuantityChange(event, index)}
          />
        </TableCell>
        <TableCell narrow>
          <NumberInput
            disabled
            value={(module.totalDays ? module.totalDays : "0")}
            onChange={(e) => e.preventDefault()} />
        </TableCell>
        <TableCell narrow>
          <NumberInput
            disabled
            value={(module.quantity ? (numberWithCommas(module.quantity * module.rate)) : "0")}
            onChange={(e) => e.preventDefault()} />
        </TableCell>
      </TableRow>
    );
    if (modules.length > 0) {
      return(
        <Table fullwidth>
          <thead>
            <TableRow>
              <TableCell heading narrow>Module</TableCell>
              <TableCell heading wide>Description</TableCell>
              <TableCell heading wide>Details</TableCell>
              <TableCell heading narrow>Rate</TableCell>
              <TableCell heading narrow>Qty</TableCell>
              <TableCell heading narrow>Total days</TableCell>
              <TableCell heading narrow>Total</TableCell>
            </TableRow>
          </thead>
          <tbody>
            {rows}
            <TableRow>
              <TableCell></TableCell>
              <TableCell colspan="2">Production management</TableCell>
              <TableCell>
                <FormField addon="%">
                  <NumberInput
                    name="productionManagementPercentage"
                    value={productionManagementPercentage}
                    placeholder="0"
                    onChange={onChange} />
                </FormField>
              </TableCell>
              <TableCell colspan="2"></TableCell>
              <TableCell>
                <NumberInput
                  name="productionManagementCost"
                  disabled
                  value={numberWithCommas(productionManagementCost)}
                  placeholder="0.00"
                  onChange={onChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell colspan="5">Rendering costs</TableCell>
              <TableCell>
                <NumberInput
                  name="renderingCost"
                  value={renderingCost}
                  placeholder="0"
                  onChange={onChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Extra costs</TableCell>
              <TableCell>
                <TextInput
                  name="extraCostsDescription"
                  value={extraCostsDescription}
                  placeholder="Details"
                  onChange={onChange} />
              </TableCell>
              <TableCell colspan="3"></TableCell>
              <TableCell>
                <NumberInput
                  name="extraCosts"
                  value={extraCosts}
                  placeholder="0"
                  onChange={onChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell colspan="2">Discount</TableCell>
              <TableCell>
                <FormField addon="%">
                  <NumberInput
                    name="discountPercentage"
                    value={discountPercentage}
                    placeholder="0"
                    onChange={onChange} />
                </FormField>
              </TableCell>
              <TableCell colspan="2"></TableCell>
              <TableCell>
                <NumberInput
                  disabled
                  name="discount"
                  value={numberWithCommas(discount)}
                  onChange={onChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell heading colspan="5">Sub total</TableCell>
              <TableCell>{numberWithCommas(net)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell heading colspan="2">VAT</TableCell>
              <TableCell>
                <FormField addon="%">
                <NumberInput
                  name="vatRate"
                  placeholder="0"
                  value={vatRate}
                  onChange={onChange} />
                </FormField>
              </TableCell>
              <TableCell colspan="2"></TableCell>
              <TableCell>{numberWithCommas(vat)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell colspan="5" heading>Total</TableCell>
              <TableCell heading>{numberWithCommas(total)}</TableCell>
            </TableRow>
          </tbody>
        </Table>
      );
    } else {
      return null
    }
  }
}

export default CostSummary;
