import React from 'react';
import Button from './Button.js';

function SubmitButton({onClick, loading}) {
  return (
    <Button loading={loading} type="button" variant="primary" label="Submit" onClick={onClick} />
  )
}

export default SubmitButton;
