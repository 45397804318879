import React from 'react';
import Button from './Button';

function Dropdown(props) {
  let fullClassName = "dropdown";
  if (props.hoverable) fullClassName += " is-hoverable";
  if (props.right) fullClassName += " is-right";
  if (props.active) fullClassName += " is-active";
  return(
    <div className={fullClassName}>
      <div className="dropdown-trigger">
        <Button
          onClick={props.onClick}
          disabled={props.disabled}
          icon={(props.icon ? props.icon : "angle-down")}
          type="button"
          label={props.label} />
      </div>
      <div className="dropdown-menu">
        <div className="dropdown-content is-radiusless">
            {props.children}
        </div>
      </div>
    </div>
  )
}

export default Dropdown;
