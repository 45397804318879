import React from 'react';
import IterationTab from './IterationTab';
import Tabs from './Tabs';
import Button from './Button';

class IterationTabs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.id,
      newIteration: false
    }
  }

  render() {
      const component = this;
      const iterations = this.props.iterations.sort((a, b) => a.id - b.id);
      const tabs = iterations.map((iteration, index) =>
        <IterationTab
          key={iteration.id}
          id={iteration.id}
          editable={!iteration.marked_as_sent && !iteration.converted_to_invoice}
          reference={iteration.reference}
          active={iteration.reference === component.props.active}
          onClick={this.props.onChange} />
      );
      return(
        <div className="is-relative">
          {this.props.newIterationButton &&
            <Button
              className="absolutely-right"
              icon="plus"
              onClick={this.props.onNewIteration}
              label={"New iteration"} />
          }
          <Tabs>
            {tabs}
          </Tabs>
          <div className="is-clearfix"></div>
        </div>
      )
  }
}

export default IterationTabs;
