import React from 'react';
import Select from './Select.js';
import FormField from './FormField.js';

function ItemsPerPageSelector({value, disabled, onChange}) {
  const options = [
    {
      key: 10,
      label: "10 items",
      value: 10
    },
    {
      key: 20,
      label: "20 items",
      value: 20
    },
    {
      key: 50,
      label: "50 items",
      value: 50
    },
    {
      key: 100,
      label: "100 items",
      value: 100
    }
  ]
  return (
    <FormField className="has-width-200">
      <Select
        disabled={disabled}
        placeholder={"Select itemsper page"}
        name="itemsPerPage"
        value={value}
        onChange={onChange}
        options={options}
      />
    </FormField>
  );
}

export default ItemsPerPageSelector;
