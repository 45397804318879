import React from 'react';
import TableRow from '../components/TableRow';
import TableCell from '../components/TableCell';
import QuoteActions from '../components/QuoteActions';
import QuoteAction from '../components/QuoteAction';
import EditButton from '../components/EditButton';
import DeleteButton from '../components/DeleteButton';
import numberWithCommas from './../functions/numberWithCommas.js';

function Payment(props) {
  return(
    <tbody>
      <TableRow>
        <TableCell>
          {props.payment.date}
        </TableCell>
        <TableCell>
          {numberWithCommas(props.payment.amount)}
        </TableCell>
        {!props.readonly &&
          <TableCell className="has-text-right">
            <QuoteActions>
              <QuoteAction>
                <EditButton label="" onClick={props.onEdit} />
              </QuoteAction>
              <QuoteAction>
                <DeleteButton label="" onClick={props.onDelete} />
              </QuoteAction>
            </QuoteActions>
          </TableCell>
        }

      </TableRow>
    </tbody>
  )
}

export default Payment;
