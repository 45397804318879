import React from 'react';
import TableCell from './TableCell';
import TableRow from './TableRow';
import Tag from './Tag';
import DownloadButton from './DownloadButton';
import Button from './Button';
import Modal from './Modal';
import PublishForm from './PublishForm';
import ApproveButton from './ApproveButton';
import EditQuoteButton from './EditQuoteButton';
import QuoteActions from './QuoteActions';
import QuoteAction from './QuoteAction';

class Iteration extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
      approving: false,
      publishing: false
    }
    this.requests = [];
    this.showPublishForm = this.showPublishForm.bind(this);
    this.handlePublishSucces = this.handlePublishSucces.bind(this);
  }

  componentWillUnmount() {
    this.props.server.cancelRequests(this.requests);
  }

  showPublishForm(event) {
    this.setState({ publishing: true });
    event.preventDefault();
  }

  handlePublishSucces(data) {
    this.setState({ publishing: false });
    this.props.onSucces(data);
  }

  handleApproveSucces(data) {
    this.setState({ approving: false });
    this.props.onSucces(data);
  }

  render() {
    const { data, quoteNumber, quoteId } = this.props;
    return(
      <TableRow backgroundColor="light">
        <TableCell>{quoteNumber}</TableCell>
        <TableCell>{data.reference}</TableCell>
        <TableCell>{data.author}</TableCell>
        <TableCell>{data.project_title}</TableCell>
        <TableCell>—</TableCell>
        <TableCell>
          <Tag
          color={(data.marked_as_sent ? "white" : false)}
          background={(data.marked_as_sent ? (data.approved ? "primary" : "info") : false)}
          label={(data.marked_as_sent ? (data.approved ? "Approved" : "Sent") : "Draft")} />
        </TableCell>
        <TableCell className="has-text-right table-cell--actions">
          <QuoteActions>
            <QuoteAction>
              <ApproveButton
                id={data.id}
                quoteId={quoteId}
                server={this.props.server}
                onClick={(e) => e.preventDefault()}
                onSucces={this.props.onSucces}
                onError={this.props.onError}
                disabled={!data.marked_as_sent || data.approved} />
            </QuoteAction>
            {this.state.publishing &&
              <Modal>
                <PublishForm
                  id={quoteId}
                  iterationId={data.id}
                  subject={data.project_title + " - " + data.reference}
                  message={"<p>Dear " + data.contact + "</p>" + data.introduction_text + "<p>For and on behalf of Three Thirds,<br/>" + this.props.server.apiUser().name + "</p>"}
                  server={this.props.server}
                  onCancel={(e) => this.setState({ publishing: false})}
                  onSucces={this.handlePublishSucces} />
              </Modal>
            }
            <QuoteAction>
              <Button
                tooltip="Publish"
                disabled={data.marked_as_sent}
                icon="envelope"
                onClick={this.showPublishForm} />
            </QuoteAction>
            <QuoteAction>
              <DownloadButton
                server={this.props.server}
                onError={this.props.onError}
                url={'quota/iteration?action=download&id=' + data.id} />
            </QuoteAction>
            <QuoteAction>
              <EditQuoteButton
                id={this.props.quoteId}
                iterationId={data.id}
                readOnly={data.marked_as_sent} />
            </QuoteAction>
          </QuoteActions>
        </TableCell>
      </TableRow>
    )
  }

}
export default Iteration;
