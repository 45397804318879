import React from 'react';

function Tag(props) {
  let className = "tag";
  if (props.size) className+= " is-" + props.size;
  else className+= " is-medium";
  if (props.variant) className += " is-" + props.variant;
  if (props.disabled) {
    className += " has-text-grey-light has-background-light";
  }
  else {
    if (props.link) className += " is-link";
    if (props.color) className += " has-text-" + props.color;
    if (props.background) className += " has-background-" + props.background;
  }
  if (props.className) className += " " + props.className;
  if (props.link) {
    return <a onClick={props.onClick} className={className}>{props.label}</a>
  }
  else {
    return <span onClick={props.onClick} className={className}>{props.label}</span>
  }
}

export default Tag;
