import React from 'react';

function Menu({children, className}) {
  return(
    <aside className={"menu " + className}>
      {children}
    </aside>
  )
}

export default Menu;
