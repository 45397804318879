import React from 'react';
import Box from './Box';
import Notification from './Notification';

function ModalContent({notification, message, children, onClose, title}) {
  if (notification) {
    return(
      <Notification onClose={onClose}>{children}</Notification>
    )
  }
  else {
    return (
      <Box>
        {children}
      </Box>
    )
  }

}

export default ModalContent;
