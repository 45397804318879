import React from 'react';

function TableCell({children, heading, narrow, wide, colspan, className}) {
  let fullClassName = "table-cell";
  if (narrow) fullClassName += " table-cell-narrow";
  if (wide) fullClassName += " table-cell-wide";
  if (className) fullClassName += " " + className;
  if (heading === true) {
    return (
      <th className={fullClassName} colSpan={colspan}>
        {children}
      </th>
    )
  } else {
    return (
      <td className={fullClassName} colSpan={colspan}>
        {children}
      </td>
    )
  }

}

export default TableCell;
