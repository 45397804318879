import React from 'react';
import LogOutButton from './LogOutButton';
import AppSwitcher from './AppSwitcher';
import SiteName from './SiteName';
import Logo from './Logo';
import { Link } from 'react-router-dom';

function NavigationBar({loggedIn, onLogout, server, currentApp, readonly}) {
  return(
    <nav className="navbar">
      <div className="navbar-item">
        {readonly &&
          <Logo />
        }
        {!readonly &&
          <AppSwitcher currentApp={currentApp} />
        }
      </div>
      <div className="navbar-item">
        <Link to={"/" + currentApp}>
          <SiteName name={currentApp} />
        </Link>
      </div>
      {!readonly &&
        <div className="navbar-end">
          <div className="navbar-item">
            <LogOutButton server={server} onClick={onLogout} />
          </div>
        </div>
      }
    </nav>
  )
}

export default NavigationBar;
