import React from 'react';
import Button from './Button.js';

function CancelButton({onClick}) {
  return(
    <Button onClick={onClick} variant="danger" label="Cancel" />
  )
}

export default CancelButton;
