import React from 'react';
import Button from './Button';
import LinkButton from './LinkButton';

class TrackaPublicLinkButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      link: null,
    }
    this.getPublicLink = this.getPublicLink.bind(this);

  }

  getPublicLink(event) {
    const newWindow = window.open("");
    const component = this;
    const {customerId, server, onError} = component.props;
    component.setState({ loading: true });
    const callback = function(response) {
      component.setState({ loading: false });
      if (response.error) {
        newWindow.close();
        onError(response.data);
      }
      else {
        newWindow.location.href = response.data;
        component.setState({ link: response.data });
      }
    }
    this.requests = server.request(callback, 'get', 'tracka/link?customer=' + customerId );
    if (event) event.preventDefault();
  }

  componentWillUnmount() {
    this.request && this.props.server.abortRequest(this.request);
  }

  render() {
    if (this.state.link) {
      return(
        <LinkButton
          url={this.state.link}
          target="_blank"
          icon="link"
          tooltip="Shareable Link" />
      )
    }
    else {
      return(
        <Button
          loading={this.state.loading}
          onClick={this.getPublicLink}
          icon="link"
          tooltip="Shareable Link" />
      )
    }
  }
}

export default TrackaPublicLinkButton;
