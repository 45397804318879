import React from 'react';
import FormControl from './FormControl.js';

function TextInput({expanded, bold, placeholder, disabled, readOnly, value, name, isStatic, onChange}) {
  let className = "input";
  if (isStatic) className += " is-static";
  if (bold) className += " input--bold";
  return (
    <FormControl variant={(expanded ? "expanded" : null)}>
      <input
        className={className}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        value={(value ? value : "")}
        type="text"
        name={name}
        onChange={onChange} />
    </FormControl>
  )
}

export default TextInput;
