import React from 'react';

function LevelRight(props) {
  return (
    <div className="level-right">
      {props.children}
    </div>
  )
}

export default LevelRight;
