import React from 'react';
import Button from './Button';

function DuplicateButton({className, label, onClick, tooltip}) {
  return (
    <Button
      tooltip={tooltip}
      className={className}
      onClick={onClick}
      label={(label === undefined ? 'Duplicate': label)}
      icon="copy" />
  );

}

export default DuplicateButton;
