import React from 'react';
import 'bulma-tooltip/dist/css/bulma-tooltip.min.css';
import FormControl from './FormControl.js';
import Icon from './Icon.js';

class Button extends React.Component {

  render() {
    let label;
    let className = "is-inline-block button";
    if (this.props.variant) className += " is-" + this.props.variant;
    else className += " is-light";
    if (this.props.size) className += " is-" + this.props.size;
    else className += " is-normal";
    if (this.props.color) className += " has-text-" + this.props.color;
    if (this.props.loading) className += " is-loading";
    if (this.props.tooltip) className += " tooltip";
    if (this.props.tooltipActive) className += " is-tooltip-active";
    if (this.props.tooltipPosition) className += " is-tooltip-" + this.props.tooltipPosition;
    if (this.props.className) className += " " + this.props.className;
    if (this.props.icon) {
      label =
      <span className={(this.props.loading ? " invisible" : null)}>
        <Icon name={this.props.icon} color={this.props.color}/>
        {this.props.label &&
          <span className="has-margin-right">{this.props.label}</span>
        }
      </span>
    } else {
      label = this.props.label;
    }
    return (
      <FormControl>
        <button
          data-tooltip={this.props.tooltip}
          disabled={this.props.disabled}
          type={(this.props.type ? this.props.type : "button")}
          className={className}
          onClick={this.props.onClick}>
          {label}
        </button>
      </FormControl>
    )
  }
}

export default Button;
