import React from 'react';
import TextInput from './TextInput';

class NumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: props.value}
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    let newValue = event.target.value.replace(/[^0-9.]/g,"");
    event.target.value = newValue;
    this.props.onChange(event);
  }

  render() {
    const {expanded, placeholder, disabled, readOnly, value, name} = this.props;
    return (
      <TextInput
        expanded={expanded}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        value={String(value)}
        type="text"
        name={name}
        onChange={this.handleChange} />
    )
  }

}

export default NumberInput;
