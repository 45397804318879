import React from 'react';
import Iteration from './Iteration';

class Iterations extends React.Component {

  render() {
    const component = this;
    const iterations = [];
    let approved = false;
    for (let i=0; i < this.props.iterations.length; i++) {
      iterations.push(this.props.iterations[i]);
      if (this.props.iterations[i].approved) approved = i;
    }
    if (this.props.skipLatest) iterations.splice((approved ? approved : 0), 1);
    const iterationComponents = iterations.map((iteration, index) =>
      <Iteration
        onError={component.props.onError}
        onSucces={component.props.onSucces}
        quoteId={component.props.id}
        quoteNumber={component.props.quoteNumber}
        server={component.props.server}
        key={iteration.id}
        data={iteration} />
    );
    return iterationComponents;
  }

}

export default Iterations;
