import React from 'react';
import Modal from './Modal';
import Button from './Button';

class SyncCustomersButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      response: []
    }
    this.requests = [];
    this.sync = this.sync.bind(this);
  }

  componentWillUnmount() {
    this.props.server.cancelRequests(this.requests);
  }

  sync(event) {
    const component = this;
    const responses = [];
    const { server } = this.props;
    component.setState({
      loading: true,
      response: []
    });
    const syncPaymentsCallback = function(response) {
      component.setState({ loading: false });
      responses.push(response.data);
      component.setState({ response: responses });
    }
    const syncCustomersCallback = function(response) {
      responses.push(response.data);
      if (response.error) {
        component.setState({
          loading: false,
          response: responses
        });
      }
      else {
        component.requests.push(server.request(syncPaymentsCallback, 'get', 'tracka/payment/syncWithKashFlow' ));
      }
    }
    component.requests.push(server.request(syncCustomersCallback, 'get', 'customer/syncWithKashFlow' ));
    if (event) event.preventDefault();
  }

  render() {
    const {disabled} = this.props;
    const {loading, response} = this.state;
    let responseString = [];
    for (let i=0; i < response.length; i++) responseString.push(<p>{response[i]}</p>);
    responseString.push(<p>Please refresh the page.</p>);
    return(
      <span>
      {response.length > 0 &&
        <Modal notification>
            {responseString}
        </Modal>
      }
      <Button
        tooltip="Sync with KashFlow"
        disabled={disabled}
        loading={loading}
        onClick={this.sync}
        icon="sync" />
      </span>
    )
  }
}

export default SyncCustomersButton;
