import React from 'react';

function Switch({name, checked, disabled, onChange}) {
  return (
    <div>
    <input
      disabled={disabled}
      type="checkbox"
      id={name}
      name={name}
      className="switch"
      checked={checked}
      onChange={onChange} />
      <label htmlFor={name}></label>
    </div>
  )
}

export default Switch;
