import React from 'react';
import Modal from './Modal';
import FormField from './FormField';
import Logo from './Logo';
import AppLauncherButton from './AppLauncherButton';
import Divider from './Divider';

function AppLauncher(props) {
  return(
    <Modal>
      <FormField className="has-text-centered">
        <Logo />
      </FormField>
      <Divider />
      <FormField variant="grouped is-grouped-centered" className="app-launcher">
        <AppLauncherButton app="quota" />
        <AppLauncherButton app="tracka" />
      </FormField>
    </Modal>
  )
}

export default AppLauncher;
