import React from 'react';
import FormControl from '../components/FormControl';
import Button from '../components/Button';

function Checkbox({name, checked, disabled, onChange}) {
  return (
    <FormControl>
      <Button
        icon="check"
        variant="outlined"
        color={(checked ? "dark" : "transparent")}
        disabled={disabled}
        onClick={(e) => onChange(e, !checked)} />
      <label htmlFor={name}></label>
    </FormControl>
  )
}

export default Checkbox;
