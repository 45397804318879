import React from 'react';

function FormControl({children, variant, addons}) {
  return(
    <div className={"control" + (variant ? " is-" + variant : "")}>
      {children}
    </div>
  )
}

export default FormControl;
