import React from 'react';
import SentInvoices from './SentInvoices';
import Iterations from './Iterations';

class QuoteHistory extends React.Component {

  render() {
    const {quoteNumber, skipLatest, invoicesSent, iterations, invoices, server, id, onError, onSucces, onCancel} = this.props;
    const invoicesAndIterations = [];
    invoicesAndIterations.push(<SentInvoices
      key="invoices"
      skipLatest={invoicesSent}
      invoices={invoices}
      server={server}
      onError={onError}
      onSucces={onSucces}
      onCancel={onCancel}
    />);
    invoicesAndIterations.push(<Iterations
      key="iterations"
      skipLatest={skipLatest}
      quoteNumber={quoteNumber}
      iterations={iterations}
      onError={onError}
      onSucces={onSucces}
      server={server}
      id={id}
     />);
    return invoicesAndIterations;
  }

}

export default QuoteHistory;
