import React from 'react';
import Cookies from 'js-cookie';
import Router from './Router';
import axios from 'axios';


class App extends React.Component {

  constructor(props) {
    super(props);
    const name = Cookies.get('name');
    const token = Cookies.get('token');

    this.state = {
      authenticated: name && token,
      sessionToken: token,
      userFullName: name
    }

    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogin(fullName, token) {
    Cookies.set('token', token, { expires: 1 });
    Cookies.set('name', fullName, { expires: 1 });
    this.setState({
      authenticated: true,
      userFullName: fullName,
      sessionToken: token
    });
  }

  handleLogout(event) {
    Cookies.remove('name');
    Cookies.remove('token');
    this.setState({
      authenticated: false,
      sessionToken: null,
      userFullName: null,
      username: null,
      password: null,
    });
    event.preventDefault();
  }

  server = {
    app: this,
    //apiURL: "http://localhost:8888/threethirds-app/back/",
    apiURL: "/back/",
    apiUser: function($value = 'fullName') {
      const app = this.app;
      if ($value === 'fullName') return { name: app.state.userFullName};
      if ($value === 'credentials') return { username: app.state.username, password: app.state.password };
    },
    url: function(parameters) {
      const app = this.app;
      const url = "https://" + app.state.username + ':' + app.state.password + '@' + this.apiURL.replace('https://', '') + parameters;
      return url;
    },
    abortRequest: function(source) {
      source.cancel( 'Operation canceled due component being unmounted.' )
    },
    abortDownload: function(xhr) {
      xhr.abort();
    },
    cancelRequests: function(requests) {
      for (let i=0; i < requests; i++) this.abortRequest(requests[i]);
    },
    download: function(callback, url) {
      let result = {};
      this.currentRequest = new XMLHttpRequest();
      const xhr = this.currentRequest;
      xhr.responseType = 'arraybuffer';
      xhr.open('GET', this.apiURL + url, true);
      xhr.setRequestHeader("Authorization", "Bearer " + this.app.state.sessionToken);
      xhr.onload = function(e) {
        if (xhr.readyState === 4) {
          if (this.status === 200) {
             result.error = false;
             result.message = 'File downloaded succesfully';
             var filename = "";
             var disposition = xhr.getResponseHeader('Content-Disposition');
             if (disposition && disposition.indexOf('attachment') !== -1) {
               var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
               var matches = filenameRegex.exec(disposition);
               if (matches != null && matches[1]) {
                 filename = matches[1].replace(/['"]/g, '');
               }
             }
             var blob=new Blob([this.response], {type:"application/pdf"});
             var link=document.createElement('a');
             link.href=window.URL.createObjectURL(blob);
             link.download=filename;
             link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
          } else {
            const noMessageFromServer = e.response === undefined;
            const errorMessage = (noMessageFromServer) ? 'Something went wrong.' : e.response;
            result.message = errorMessage;
            result.error = true;
          }
          console.log(result);
          callback(result);
       }
      };
      xhr.send();
      return xhr;
    },
    request: function(callback, method, url, data, username = false, password = false) {
      const cancelToken = axios.CancelToken;
      const source = cancelToken.source();
      let settings = {
        cancelToken: source.token,
        method: method,
        url: this.apiURL + url,
        data: data
      }
      if (username && password) {
        settings = {
          ...settings,
          withCredentials: true,
          auth: {
            username: username,
            password: password
          }
        }
      }
      else {
        axios.defaults.headers.common = {'Authorization': "Bearer " + this.app.state.sessionToken}
      }
      let result = {};
      result.path = url;
      const app = this.app;
      axios(settings)
        .then(function (response) {
          result.error = false;
          result.data = response.data.result;
          result.attributes = response.data.attributes;
          console.log(response.data);
          callback(result);
        })
        .catch(function (error) {
          if (!axios.isCancel(error)) {
            const noMessageFromServer = error.response === undefined;
            const errorMessage = (noMessageFromServer) ? 'Something went wrong.' : error.response.data;
            result.error = true;
            result.data = errorMessage;
            if (!noMessageFromServer && error.response.status === 401) {
              Cookies.remove('token');
              Cookies.remove('name');
              app.setState({
                authenticated: false,
                userFullName: "",
                sessionToken: null
              })
            }
            console.log(result);
            callback(result);
          } else {
            console.log('Request cancelled.');
          }
        })
      return source;
    }
  }

  render() {

    return (
      <Router
        userFullName={this.state.userFullName}
        handleLogin={this.handleLogin}
        handleLogout={this.handleLogout}
        server={this.server}
        authenticated={this.state.authenticated} />
      )
    }

}

export default App;
