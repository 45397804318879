import React from 'react';
import { Link } from 'react-router-dom';
import FormControl from './FormControl';

import SiteName from './SiteName';

function AppLauncherButton({app}) {
  return(
    <FormControl>
    <Link to={"/" + app} className="app-launcher__button">

        <SiteName name={app} />
    </Link>
  </FormControl>
  )
}

export default AppLauncherButton;
