import React from 'react';

function Right({children}) {
  return (
    <div className="is-pulled-right">
      {children}
    </div>
  )
}

export default Right;
