import React from 'react';
import Icon from './Icon';
import Tag from './Tag';

function DragHandle(props) {
  return (
    <Tag label={<Icon className="draggable" name="bars" />} background="transparent" />
  )
}

export default DragHandle;
