import React from 'react';
import * as ReactDOM from 'react-dom';
import dragula from 'react-dragula';
import DynamicField from './DynamicField';
import FormField from './FormField';
import CreateButton from './CreateButton';
import TableCell from './TableCell';
import TableRow from './TableRow';

class DynamicFields extends React.Component {
  constructor(props) {
    super(props);
    this.createField = this.createField.bind(this);
    this.deleteField = this.deleteField.bind(this);
  }

  componentDidMount() {
    if (this.props.dragAndDrop) {
      const container = ReactDOM.findDOMNode(this);
      const drake = dragula([container], {
        moves: function (el, source, handle, sibling) {
          const className = " " + handle.className + " ";
          if (className.indexOf(" draggable ") > -1) {
            return true;
          }
        },
        accepts: function (el, source, handle, sibling) {
          if (sibling !== null) return true;
        }
      });
      const component = this;
      drake.on('drop', function(el, target, source, sibling) {
        // cancel dom change as we use props
        drake.cancel(true);
        // get begin and end position of moved element
        const elementIndex = parseInt(el.getAttribute('data-index'), 10);
        let siblingIndex = parseInt(sibling.getAttribute('data-index'), 10);
        // if te sibling is the row with the 'add module' button, it wil be NaN
        const willBeLast = isNaN(siblingIndex);
        if (willBeLast) siblingIndex = component.props.fields.length - 1;
        const movedElement = {
          beginPosition: elementIndex,
          endPosition: siblingIndex
        }
        const movingDown = movedElement.endPosition > movedElement.beginPosition;
        if (movingDown && !willBeLast) movedElement.endPosition = movedElement.endPosition - 1;

        // make empty array
        const beginPositions = component.props.fields;

        const endPositions = [];
        for (let i=0; i < beginPositions.length; i++) endPositions.push(null);
        // rearrange array
        for (let fieldKey = 0; fieldKey < beginPositions.length; fieldKey++) {
          if (fieldKey === movedElement.beginPosition) {
            endPositions[movedElement.endPosition] = fieldKey;
          }
          else if (movedElement.beginPosition < fieldKey && movedElement.endPosition >= fieldKey) {
            endPositions[fieldKey - 1] = fieldKey;
          }
          else if (movedElement.beginPosition > fieldKey && movedElement.endPosition <= fieldKey) {
            endPositions[fieldKey + 1] = fieldKey;
          }
          else {
            endPositions[fieldKey] = fieldKey;
          }
        }
        component.props.onDragAndDrop(endPositions);
      });
    }
  }

  createField(event, key) {
    this.props.onCreateField(key);
    event.preventDefault();
  }

  deleteField(event, key) {
    this.props.onDeleteField(key);
    event.preventDefault();
  }

  render() {
    const component = this;
    const newKey = component.props.fields.length;
    const createButton = <FormField><CreateButton label={"Add " + this.props.fieldSingleName} onClick={(event) => this.createField(event, newKey)} /></FormField>
    const fields = this.props.fields.map((field, index) =>
      <DynamicField type={component.props.fieldType} key={index} onDelete={(event) => this.deleteField(event, field.index)}>
        {field}
      </DynamicField>
    );
    if(this.props.fieldType === "tableRow") {
      return(
        <tbody id={(this.props.id ? this.props.id : null)}>
          {fields}
          <TableRow className="last">
            <TableCell className="has-no-padding-left has-padding-top" colspan={this.props.columns}>{createButton}</TableCell>
          </TableRow>
        </tbody>
      );
    }
    else {
      return (
        <div>
          {fields}
          {createButton}
        </div>
      )
    }

  }
}

export default DynamicFields;
