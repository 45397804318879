import React from 'react';

function Dynamic({visible, children}) {
  return (
    <div className={(visible ? "is-visible" : "is-hidden")}>
      {children}
    </div>
  )
}

export default Dynamic;
