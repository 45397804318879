import React from 'react';

function Box(props) {
  return(
    <div className="box is-radiusless">
      {props.children}
    </div>
  )
}

export default Box;
