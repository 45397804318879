import React from 'react';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';
import Notification from '../components/Notification';
import Pagination from '../components/Pagination';
import ItemsPerPageSelector from '../components/ItemsPerPageSelector';
import Quote from '../components/Quote';
import Table from '../components/Table';
import TableCell from '../components/TableCell';
import CreateButton from './CreateButton';
import Divider from './Divider';
import Dynamic from './Dynamic';

class Quotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstTimeLoading: true,
      quotes: [],
      loading: true,
      quotesLoaded: false,
      error: null,
      succes: false,
      itemsPerPage: 50,
      pageNumber: 0,
      totalPages: 0
    }
    this.handleError = this.handleError.bind(this);
    this.handleLoading = this.handleLoading.bind(this);
    this.handleSucces = this.handleSucces.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleItemsPerPageChange = this.handleItemsPerPageChange.bind(this);

  }

  componentWillUnmount() {
    this.request && this.props.server.abortRequest(this.request);
  }

  componentDidUpdate(previousProps) {
    if (previousProps.customer !== this.props.customer) this.loadQuotes();
  }

  componentDidMount() {
    this.loadQuotes();
  }

  loadQuotes(pageNumber = 0, itemsPerPage = this.state.itemsPerPage) {
    const app = this;
    this.setState({
      quotes: [],
      loading: true,
      quotesLoaded: false
    });
    const callback = function(response) {
      if (response.error) {
        app.setState({
          succes: false,
          error: response.data,
          firstTimeLoading: false,
          firstTimeLoaded: false,
          quotesLoaded: false,
          loading: false
        });
      }
      else {
        app.setState({
          quotes: response.data,
          error: null,
          quotesLoaded: true,
          firstTimeLoading: false,
          firstTimeLoaded: true,
          loading: false,
          totalPages: Math.ceil(response.attributes.count / itemsPerPage) - 1,
          pageNumber: pageNumber
        })
      }
    }
    this.request = this.props.server.request(callback, 'get', (this.props.customer ? 'quota/quote?customer=' + this.props.customer +'&' : 'quota/quote?') + 'offset=' + pageNumber * itemsPerPage + '&limit=' + itemsPerPage);
  }

  handleSucces(message) {
    this.setState({
      error: false,
      succes: message
    });
    this.loadQuotes();
  }
  handleLoading(state) {
    this.setState({loading: state})
  }

  handleError(message) {
    this.setState({error: message, succes: false});
  }

  handlePageChange(targetPage) {
    this.loadQuotes(targetPage);
  }

  handleItemsPerPageChange(event) {
    const target = event.target;
    const value = target.value;
    this.setState({
      itemsPerPage: value,
      pageNumber: 0
    });
    this.loadQuotes(0, value);
    event.preventDefault();
  }

  render() {
    if (this.state.firstTimeLoading) {
      return <Loader />;
    }
    else if (!this.state.quotesLoaded && !this.state.firstTimeLoaded) {
      return <Notification danger>{this.state.error}</Notification>;
    }
    else {
      const quotes = this.state.quotes.map((quote) => <Quote key={quote.number} server={this.props.server} onError={this.handleError} onSucces={this.handleSucces} handleLoading={this.handleLoading} data={quote} customerLink={!this.props.customer} /> );
      return(
        <div>
          {this.state.loading && <Loader />}
          <Dynamic visible={!this.state.loading}>
            <Notification danger={!this.state.succes} succes={this.state.succes}>
              {(this.state.error ? this.state.error : this.state.succes)}
            </Notification>
            <Link to="/quota/quote/create">
              <CreateButton
                url="quota/quote/create"
                label="New quote" />
            </Link>
            <Divider />
            <Table fullwidth hoverable>
              <thead>
                <tr>
                  <TableCell heading>
                    #
                  </TableCell>
                  <TableCell heading>
                    Ref.
                  </TableCell>
                  <TableCell heading>
                    Author
                  </TableCell>
                  <TableCell heading>
                    Title
                  </TableCell>
                  <TableCell heading>
                    Customer
                  </TableCell>
                  <TableCell heading>
                    Status
                  </TableCell>
                  <TableCell heading className="has-text-right">
                    Actions
                  </TableCell>
                </tr>
              </thead>
              {quotes}
            </Table>
            <Divider />
            <ItemsPerPageSelector
              value={this.state.itemsPerPage}
              onChange={this.handleItemsPerPageChange} />
            <Pagination totalPages={this.state.totalPages} currentPageNumber={this.state.pageNumber} onPageChange={this.handlePageChange} />
          </Dynamic>
        </div>
      )
    }
  }
}

export default Quotes;
