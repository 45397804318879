import React from 'react';

function MenuList({children}) {
  return(
    <ul className="menu-list">
      {children}
    </ul>
  )
}

export default MenuList;
