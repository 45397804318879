import React from 'react';

function Column(props) {
  let className = "column";
  if (props.size) className += " is-" + props.size;
  return(
    <div className={className}>
      {props.children}
    </div>
  )
}

export default Column;
